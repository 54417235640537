import React from 'react';
import Translate from '../Translate';
import './unsupported.style.scss';

interface UnsupportedProps {
    isUnsupportedBrowser?: boolean;
}

const Unsupported: React.FC<UnsupportedProps> = ({isUnsupportedBrowser}) => {
    const translationKey = isUnsupportedBrowser ? 'BROWSER_UNSUPPORTED.VIEW.TEXT' : 'UNSUPPORTED.VIEW.TEXT';

    return (
        <div className='ts-container device-unsupported-container text-center'>
            <div className='device-unsupported-message'>
                <img src='img/logo-entry.png' alt='TechSee Logo' />
                <br />
                <Translate text={translationKey} />
            </div>
        </div>
    );
};

export default Unsupported;
