'use strict';

import isEmpty from 'lodash/isEmpty';

import {CHECK_PENDING_REQUEST_INTERVAL, CHECK_PENDING_REQUEST_ATTEMPTS} from './ts-state-helper.settings';

// Service that wraps $state.go and allows changes in url, only when they are
// initiated by the program. i.e. it blocks use of the back button to switch
// between states.

export interface ITsStateHelperService {
    init($state: any, $rootScope: any, $http: any): void;
    enable(): void;
    disable(): void;
    go(state: any, params: any, options?: any): void;
    safeGo(state: any, params: any, options?: any): void;
}

export class TsStateHelperService implements ITsStateHelperService {
    private enabled: boolean;
    private bypass: boolean = false;
    private safeGoInProgress: boolean = false;
    private $rootScope: any;
    private $http: any;
    public $state: any;

    constructor() {
        this.enabled = false;

        this.init = this.init.bind(this);
        this.go = this.go.bind(this);
        this.safeGo = this.safeGo.bind(this);
    }

    init($state: any, $rootScope: any, $http: any) {
        this.$state = $state;
        this.$rootScope = $rootScope;
        this.$http = $http;

        let _resettingState = false;

        this.$rootScope.$on(
            '$stateChangeStart',
            (ev: any, toState: any, toParams: any, fromState: any, fromParams: any) => {
                if (this.enabled && !this.bypass) {
                    ev.preventDefault();

                    // re-insert the state in the browser history, since stopping
                    // the state from changing during the press of the back-button,
                    // doesn't prevent the browser from popping it.
                    if (!_resettingState) {
                        _resettingState = true;
                        this.$state.go(fromState, fromParams).finally(() => {
                            _resettingState = false;
                        });
                    }
                }
            }
        );
    }

    enable() {
        this.enabled = true;
    }

    disable() {
        this.enabled = false;
    }

    go(state: any, params: any, options?: any) {
        this.bypass = true;

        return this.$state.go(state, params, options).finally(() => {
            this.bypass = false;
        });
    }

    safeGo(state: any, params: any, options?: any) {
        if (this.safeGoInProgress) {
            return;
        }

        this.safeGoInProgress = true;

        let attempts = 0;

        const checkPendingRequests = (): any => {
            try {
                if (!isEmpty(this.$http.pendingRequests) && attempts < CHECK_PENDING_REQUEST_ATTEMPTS) {
                    attempts++;

                    return setTimeout(() => {
                        checkPendingRequests();
                    }, CHECK_PENDING_REQUEST_INTERVAL);
                }

                this.safeGoInProgress = false;

                this.go(state, params, options);
            } catch (e) {
                this.safeGoInProgress = false;

                throw e;
            }
        };

        checkPendingRequests();
    }
}
