import React from 'react';
import {RoundedButton} from '../rounded-button/RoundedButton';
import {requireImage} from '../../utils/utils';

import './ts-end-meeting-button.style.scss';

export interface TsEndMeetingButtonProps {
    isNewStyle?: boolean;
    endMeetingButtonAriaLabel?: string;
    onClick: () => void;
}

export const EndMeetingButton: React.FC<TsEndMeetingButtonProps> = ({
    isNewStyle,
    endMeetingButtonAriaLabel,
    onClick
}: TsEndMeetingButtonProps) => {
    return (
        <div className={'end-meeting-button'} aria-label={endMeetingButtonAriaLabel}>
            {isNewStyle && <RoundedButton onClick={onClick} size={'normal'} icon={'abort'} color={'red'} />}
            {!isNewStyle && (
                <button type={'button'} className={'meeting-white-button'} onClick={onClick}>
                    <img src={requireImage('button-leave.svg')} alt={'End Meeting button'} />
                </button>
            )}
        </div>
    );
};
