'use strict';

import mainView from './main.view.html';
import './main.style.scss';
import {getRootStore} from '../../../_react_/app.bootstrap';

function config($stateProvider) {
    $stateProvider.state('start.main', {
        url: '',
        template: mainView,
        controller: () => ({}),
        controllerAs: 'main',
        resolve: {
            selectState: (accountData) => {
                const voiceCalls =
                        accountData.settings &&
                        accountData.settings.fsSettings &&
                        accountData.settings.fsSettings.supportVoiceCalls,
                    callFixedNumber =
                        accountData.settings &&
                        accountData.settings.fsSettings &&
                        accountData.settings.fsSettings.voiceCallFixedNumber,
                    callGroup =
                        accountData.settings &&
                        accountData.settings.fsSettings &&
                        accountData.settings.fsSettings.voiceCallFromGroup,
                    callAddressBook =
                        accountData.settings &&
                        accountData.settings.fsSettings &&
                        accountData.settings.fsSettings.voiceCallAddressBook,
                    enableOfflineSessions = accountData.settings && accountData.settings.accountOfflineSessions;

                if (!voiceCalls || enableOfflineSessions) {
                    return;
                }

                if (callFixedNumber && !callGroup && !callAddressBook) {
                    getRootStore().stateHelper.safeGo('start.center', {});
                } else if (!callFixedNumber && (callGroup || callAddressBook)) {
                    getRootStore().stateHelper.safeGo('start.expert', {});
                }
            }
        }
    });
}

export default angular.module('states.start.main', []).config(config);
