'use strict';

import {getRootStore} from '../../../_react_/app.bootstrap';

export class CenterController {
    constructor(currentUser, csiSettings) {
        'ngInject';

        this.stateHelper = getRootStore().stateHelper;
        this.csiSettings = csiSettings;
        this.isCSI = !currentUser && csiSettings;

        if (this.isCSI && !this.csiSettings.voiceCallFixedNumber) {
            this.stateHelper.safeGo('start.main', {});
        }
    }
}
