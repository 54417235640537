import React from 'react';
import Translate from '../Translate';
import {TsPopup} from '../ts-popup/TsPopup';
import './TsActionGuidance.scss';

export interface TsActionGuidanceProps {
    width: string;
    showArrow: boolean;
    label: string;
}

export const TsActionGuidance: React.FC<TsActionGuidanceProps> = ({width, showArrow, label}) => {
    return (
        <div className='guidance-container'>
            <div className='guidance' style={{width}}>
                <Translate text={label} />
            </div>
            {showArrow && <div className='arrow'></div>}
        </div>
    );
};
