// eslint-disable-next-line no-use-before-define
import React, {Component} from 'react';

import './style.scss';

export interface IPermissionModal {
    img?: string;
    title: string;
    subTitle: any;
    onConfirmButtonText: string;
    onCancelButtonText?: string;
    onConfirm: () => void;
    onCancel?: () => void;
}

export class PermissionModal extends Component<IPermissionModal> {
    render() {
        const {title, subTitle, onConfirm, onCancel, onConfirmButtonText, onCancelButtonText, img} = this.props;

        return (
            <div className='permission'>
                <div className='content-container'>
                    <div className='wrapper confirm-preview'>
                        <img src={img} />
                    </div>
                    <div className='wrapper title'>
                        <h1>{title}</h1>
                        {typeof subTitle === 'string' && <h2>{subTitle}</h2>}
                        {typeof subTitle !== 'string' && subTitle()}
                    </div>
                    <div className='wrapper permission-ask'></div>
                    <div className='wrapper buttons'>
                        <button className={'confirm'} type={'button'} onClick={onConfirm}>
                            {onConfirmButtonText}
                        </button>
                        {onCancelButtonText && onCancel && (
                            <button className={'cancel'} type={'button'} onClick={onCancel}>
                                {onCancelButtonText}
                            </button>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}
