'use strict';

import forEach from 'lodash/forEach';
import isEmpty from 'lodash/isEmpty';
import includes from 'lodash/includes';
import {accountTypes, RolesConstant} from '@techsee/techsee-common/lib/constants/account.constants';
import * as socketEvents from '@techsee/techsee-common/lib/socket/client';
import {getRootStore} from '../../app.bootstrap';
import {
    IAccountService,
    TermsMessagePart,
    TermsTranslationResponse
} from '@techsee/techsee-client-infra/lib/services/AccountService';
import {MobileChatApiService} from '../../services/ts-chat-api/MobileChatApi';
import {ITsEventService} from '../../services/ts-event-service/ts-event-service';
import {STATUS_MESSAGES} from '../../../states/meeting/meeting.settings';
import {action, computed, observable, runInAction} from 'mobx';
import {MeetingState} from '@techsee/techsee-common/lib/constants/meeting.states.definition';
import get from 'lodash/get';
import {FlowType} from '@techsee/techsee-common/lib/constants/room.constants';
import {IBrowserUtilsService} from '@techsee/techsee-client-infra/lib/services/BrowserUtilsService';
import {IRoomService} from '@techsee/techsee-client-infra/lib/services/RoomService';
export interface TermsInfo {
    companyName?: string;
    termsURL?: string;
    accountTermsUrl?: string;
    privacyURL?: string;
    allowTermsMessage?: boolean;
    customCancelMessage?: boolean;
}

export interface ITermsNoticeController {
    termsTranslationStrings: (TermsMessagePart & {url?: string})[];
    isModalOpen: boolean;
    useCustomTermsMessage: boolean;
    openTermsLinksOnNewWindow: boolean;
    isPackageSupportedType: boolean;
    termsWindowOpen: boolean;
    isWaitingForAgentToAskReprompt: boolean;
    termsAllowButtonAria: string;
    termsCancelButtonAria: string;
    termInfo: TermsInfo | undefined;
    syncTOS(chatApi: MobileChatApiService, mode: MeetingState, role: RolesConstant): Promise<boolean>;
    ok(): void;
    cancel(): void;
    abort(chatApi: MobileChatApiService): void;
    termsToggle(visible: boolean): void;
}

export class TermsNoticeController implements ITermsNoticeController {
    private chatApi: MobileChatApiService;
    private accountType?: accountTypes;
    private ROLES = RolesConstant;
    private tsBrowserUtilsService: IBrowserUtilsService;
    private EventService: ITsEventService;
    private lastAbortedMode?: MeetingState;
    private accountApiService: IAccountService;
    private roomApiService: IRoomService;
    private resolveTOS?: (value: boolean) => void;
    private rejectTOS?: (reason?: any) => void;
    private _termInfo?: TermsInfo;

    @observable private _isModalOpen = false;
    @observable public useCustomTermsMessage: boolean = false;
    @observable public termsAllowButtonAria: string = '';
    @observable public termsCancelButtonAria: string = '';
    @observable public isWaitingForAgentToAskReprompt: boolean = false;
    @observable public termsTranslationStrings: (TermsMessagePart & {url?: string})[] = [];
    @observable public openTermsLinksOnNewWindow: boolean = false;
    @observable public termsWindowOpen: boolean = false;
    @observable public isPackageSupportedType: boolean = false;

    constructor(accountApiService: IAccountService, roomApiService: IRoomService) {
        this.chatApi = getRootStore().chatApi;
        this.EventService = getRootStore().eventService;
        this.tsBrowserUtilsService = getRootStore().browserUtilsService;
        this.EventService = getRootStore().eventService;
        this.accountApiService = accountApiService;
        this.roomApiService = roomApiService;

        this.ok = this.ok.bind(this);
        this.cancel = this.cancel.bind(this);
        this.termsToggle = this.termsToggle.bind(this);
    }

    @action
    init(): void {
        this.accountType = this.chatApi.accountSettings.accountType;

        const supportedPackageTypes = [accountTypes.vra];

        this.isPackageSupportedType = includes(supportedPackageTypes, this.accountType);

        if (get(this.chatApi, 'accountSettings.displayTerms')) {
            this.chatApi.setStatus(socketEvents.CLIENT_OUT_SET_STATUS.IS_REVIEWING_TOS, true);
        }

        if (this.chatApi.accountSettings.enableNewInvite) {
            if (this.chatApi.dashboard.isTosRepromptSent) {
                this.tosRepromptDone();
            } else if (this.chatApi.client.tosRejected && this.chatApi.client.isReviewingTOS) {
                this.waitingForTosReprompt();
            }
        }

        this.chatApi.onReady(() => {
            this.openTermsLinksOnNewWindow = !!this.chatApi.accountSettings.openTermsLinksOnNewWindow;
        });

        this.termsAllowButtonAria = getRootStore().localizationService.translate('TERMS.VIEW.ALLOW_BUTTON_ARIA');

        this.termsCancelButtonAria = getRootStore().localizationService.translate('TERMS.VIEW.CANCEL_BUTTON_ARIA');
    }

    @computed
    get isModalOpen() {
        return this._isModalOpen;
    }

    @computed
    get termInfo(): TermsInfo | undefined {
        return this._termInfo;
    }

    @action
    syncTOS(chatApi: MobileChatApiService, mode: MeetingState, role: RolesConstant): Promise<boolean> {
        this.init();

        return new Promise((resolve, reject) => {
            this.resolveTOS = resolve;
            this.rejectTOS = reject;

            const tosAcceptedRoom = this.tsBrowserUtilsService.getFromLocalStorage('tos_accepted'),
                tosAccepted = tosAcceptedRoom === chatApi.currentRoomId || chatApi.client.tosAccepted === true;

            const accountSettings = chatApi.accountSettings;
            const displayTerms = get(accountSettings, 'displayTerms');

            if (
                tosAccepted ||
                !displayTerms ||
                (role === this.ROLES.TECHNICIAN &&
                    (get(chatApi, 'client.flowType') === FlowType.fs || chatApi.getRoomCode)) ||
                this._isModalOpen ||
                this.lastAbortedMode === mode
            ) {
                if (role === this.ROLES.TECHNICIAN) {
                    this.EventService.sendEventLog(
                        'none',
                        chatApi.currentRoomId || 'none',
                        STATUS_MESSAGES.FIELD_SERVICES_SKIP_TOS
                    );
                }

                // If terms is disabled, it's the same result as approving terms
                return resolve(tosAccepted || displayTerms === false || role === this.ROLES.TECHNICIAN);
            }

            const accountId = accountSettings.accountId;

            const {displayAccountTerms, displayTechseeTerms, displayPrivacyTerms} = accountSettings;

            const termsInfo: TermsInfo = {
                companyName: accountSettings.companyName,
                termsURL: (displayTechseeTerms && accountSettings.termsURL) || '',
                accountTermsUrl: (displayAccountTerms && accountSettings.accountTermsUrl) || '',
                privacyURL: (displayPrivacyTerms && accountSettings.privacyURL) || '',
                allowTermsMessage: accountSettings.allowTermsMessage,
                customCancelMessage: accountSettings.customCancelMessage
            };

            this._termInfo = termsInfo;

            //@ts-ignore
            const clientLanguage = accountSettings.clientLanguage;

            // if the account has custom terms string, set a promise to
            const termsTranslationPromise = chatApi.accountSettings.useCustomTermsMessage
                ? this.accountApiService.getTermsTranslationStrings(accountId, clientLanguage)
                : Promise.resolve({termsMessageParts: []});

            termsTranslationPromise.then((res: TermsTranslationResponse) => {
                const {WAITING_FOR_TOS_ACCEPTANCE} = STATUS_MESSAGES;

                this.EventService.sendEventLog('none', chatApi.currentRoomId || 'none', WAITING_FOR_TOS_ACCEPTANCE);
                chatApi.sendLog(WAITING_FOR_TOS_ACCEPTANCE);

                runInAction(() => {
                    this.useCustomTermsMessage = !isEmpty(res.termsMessageParts);

                    this.termsTranslationStrings = res.termsMessageParts;

                    if (this.useCustomTermsMessage) {
                        forEach(this.termsTranslationStrings, (part) => {
                            switch (part.type) {
                                case 'TERMS':
                                    part.url = termsInfo.termsURL || '';
                                    break;
                                case 'PRIVACY':
                                    part.url = termsInfo.privacyURL || '';
                                    break;
                                case 'ACCOUNT':
                                    part.url = termsInfo.accountTermsUrl || '';
                            }
                        });
                    }

                    this._isModalOpen = true;
                });
            });
        });
    }

    abort(chatApi: MobileChatApiService): void {
        if (!this._isModalOpen) {
            return;
        }

        chatApi.setStatus(socketEvents.CLIENT_OUT_SET_STATUS.PREPARING, false);

        if (this.rejectTOS) {
            this.rejectTOS({abortedTerms: true});
            this.resolveTOS = undefined;
            this.rejectTOS = undefined;
        }
    }

    @action
    ok(): void {
        const {TERMS_ACCEPTED, TOS_ACCEPTED} = STATUS_MESSAGES;

        this.chatApi.setStatus(socketEvents.CLIENT_OUT_SET_STATUS.TOS_ACCEPTED, true);

        this.chatApi.sendLog(TOS_ACCEPTED);
        this.roomApiService.setReportedField(this.chatApi.currentRoomId || '', {key: 'tosAccepted', value: true});
        this.EventService.sendEventLog('none', this.chatApi.currentRoomId || 'none', TERMS_ACCEPTED);

        this.tsBrowserUtilsService.saveToLocalStorage('tos_accepted', this.chatApi.currentRoomId);
        this._isModalOpen = false;

        if (this.resolveTOS) {
            this.resolveTOS(true);
            this.resolveTOS = undefined;
            this.rejectTOS = undefined;
        }
    }

    @action
    cancel(): void {
        if (this.chatApi.accountSettings.enableNewInvite) {
            this.newInviteTosRejectedFlow();
        } else {
            this.oldInviteTosRejectedFlow();
        }

        this._isModalOpen = false;

        if (this.rejectTOS) {
            this.rejectTOS({rejectedTerms: true});
            this.resolveTOS = undefined;
            this.rejectTOS = undefined;
        }
    }

    @action
    waitingForTosReprompt(): void {
        this.isWaitingForAgentToAskReprompt = true;
        this.chatApi.once(
            socketEvents.CLIENT_IN_CHAT_API.DASHBOARD_IS_TOS_REPROMPT_SENT,
            this.tosRepromptDone.bind(this)
        );
    }

    @action
    tosRepromptDone(): void {
        this.isWaitingForAgentToAskReprompt = false;
        this.roomApiService.setReportedField(this.chatApi.currentRoomId || '', {
            key: 'tosRepromptDone',
            value: true
        });
    }

    newInviteTosRejectedFlow(): void {
        const {TERMS_REJECTED, TOS_REJECTED} = STATUS_MESSAGES;

        this.chatApi.setStatus(socketEvents.CLIENT_OUT_SET_STATUS.TOS_REJECTED, true);
        this.chatApi.sendLog(TOS_REJECTED);

        if (this.chatApi.dashboard.isTosRepromptSent) {
            this.chatApi.setStatus(socketEvents.CLIENT_OUT_SET_STATUS.IS_REVIEWING_TOS, false);
            this.roomApiService.setReportedField(this.chatApi.currentRoomId || '', {
                key: 'tosAccepted',
                value: false
            });

            // setTimeout(() => this.$uibModalInstance.dismiss());
        } else {
            this.waitingForTosReprompt();
        }

        this.EventService.sendEventLog('none', this.chatApi.currentRoomId || 'none', TERMS_REJECTED);
    }

    oldInviteTosRejectedFlow(): void {
        const {TERMS_REJECTED, TOS_REJECTED} = STATUS_MESSAGES;

        this.chatApi.setStatus(socketEvents.CLIENT_OUT_SET_STATUS.TOS_REJECTED, true);
        this.chatApi.setStatus(socketEvents.CLIENT_OUT_SET_STATUS.IS_REVIEWING_TOS, false);
        this.chatApi.sendLog(TOS_REJECTED);
        this.roomApiService.setReportedField(this.chatApi.currentRoomId || '', {key: 'tosAccepted', value: false});
        this.EventService.sendEventLog('none', this.chatApi.currentRoomId || 'none', TERMS_REJECTED);
        // this.$uibModalInstance.dismiss();
    }

    @action
    termsToggle(visible: boolean): void {
        this.termsWindowOpen = visible;
    }
}
