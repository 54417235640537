'use strict';

import {tsScrollDownDirective} from './ts-scroll-down/ts-scroll-down.directive.js';
import {tsVideoPublisherDirective} from './ts-video-publisher/ts-video-publisher.directive.js';
import {hideModalBackdrop} from './hide-modal-backdrop/hide-modal-backdrop.js';
import {compileDirective} from './compile/compile.directive';

export default angular
    .module('app.directives', ['passwordValidator.directive'])
    .directive('tsScrollDown', tsScrollDownDirective)
    .directive('tsVideoPublisher', tsVideoPublisherDirective)
    .directive('compile', compileDirective)
    .directive('hideModalBackdrop', hideModalBackdrop);
