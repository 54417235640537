import React from 'react';
import './ts-rounded-button.style.scss';

export interface TsRoundedButtonProps {
    icon?: string;
    color?: string;
    size?: string;
    isWideIcon?: boolean;
    disabled?: boolean;
    customCss?: string;
    onClick?: () => void;
}

const getStringValue = (value: string | undefined, defaultValue: string = ''): string => {
    return typeof value === 'string' && value.length > 0 ? value : defaultValue;
};

export const RoundedButton: React.FC<TsRoundedButtonProps> = ({
    icon,
    color,
    size,
    isWideIcon = false,
    onClick,
    disabled,
    customCss
}) => {
    const isIconVisible = (): boolean => getStringValue(icon, '').length > 0;
    const buttonColor = getStringValue(color, 'blue');
    const buttonSize = getStringValue(size, 'normal');

    return (
        <button
            type={'button'}
            disabled={disabled}
            className={`ts-rounded-button btn btn-${buttonColor} btn-${buttonSize} ${customCss}`}
            onClick={onClick}>
            {isIconVisible() && <i className={`icon icon-${icon} ${isWideIcon ? 'wide' : ''}`}></i>}
        </button>
    );
};
