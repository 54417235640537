export function requireImage(imageFileName: string, svgAsReactComponent: boolean = false) {
    const image = require('img/' + imageFileName);

    if (/\.svg$/.test(imageFileName)) {
        if (svgAsReactComponent) {
            return image.ReactComponent;
        }

        return image.default || image;
    }

    return image;
}
