'use strict';

import unsupportedView from './unsupported.view.html';
import {UnsupportedController} from './unsupported.controller.js';
import './unsupported.style.scss';
import {getRootStore} from '../../_react_/app.bootstrap';

function config($stateProvider) {
    $stateProvider.state('unsupported', {
        url: BASE_PATH + 'unsupported',
        params: {
            isUnsupportedBrowser: false
        },
        templateProvider: () => {
            const viewToggle = getRootStore().browserUtilsService.getFromLocalStorage('viewToggle'); // need to add __ts__-viewToggle: true to localStorage

            return viewToggle
                ? '<unsupported is-unsupported-browser="vm.showUnsupportedBrowser"></unsupported>'
                : unsupportedView;
        },
        controller: 'UnsupportedController',
        controllerAs: 'vm' // the convention would be to name the controller
        //`unsupported` in the view, but that would look weird
    });
}

export default angular
    .module('states.unsupported', [])
    .config(config)
    .controller('UnsupportedController', UnsupportedController);
