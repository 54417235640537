'use strict';

import startView from './start.view.html';
import {StartController} from './start.controller.js';

require('./start.style.scss');

import mainModule from './main/main.module.js';
import centerModule from './center/center.module.js';
import expertModule from './expert/expert.module.js';
import inviteModule from './invite/invite.module.js';
import {getRootStore} from '../../_react_/app.bootstrap';

function config($stateProvider) {
    $stateProvider.state('start', {
        url:
            BASE_PATH +
            'start?csi&waitForExpert&postMeeting&caseId&techName&ott&workOrderId&workOrderNumber&caseNumber',
        abstract: true,
        template: startView,
        controller: StartController,
        controllerAs: 'start',
        resolve: {
            csiSettings: (db) => {
                const accountId = getRootStore().urlUtils.getParamValue('csi');

                if (!accountId) {
                    return false;
                }

                return db.Account.clientSessionInitiation(accountId)
                    .then((res) => res.data)
                    .catch(() => false);
            },
            currentUser: (db, csiSettings) =>
                db.User.find('current')
                    .then((user) => {
                        getRootStore().roomChannelTracer.setCurrentUserId(user._id);
                        if (user.requirePasswordChange) {
                            this.stateHelper.safeGo('changePassword', {});
                        }

                        return user;
                    })
                    .catch((err) => {
                        if (csiSettings) {
                            return false;
                        }
                        if (
                            getRootStore().chatApi &&
                            getRootStore().chatApi.accountSettings &&
                            getRootStore().chatApi.accountSettings.redirectType === 'URL' &&
                            getRootStore().chatApi.accountSettings.redirectUrl
                        ) {
                            window.location.href = getRootStore().chatApi.accountSettings.redirectUrl;
                        } else if (
                            getRootStore().chatApi &&
                            getRootStore().chatApi.accountSettings &&
                            getRootStore().chatApi.accountSettings.redirectType === 'UNBRANDED_DEFAULT'
                        ) {
                            window.location.href = UNBRANDED_DEFAULT_REDIRECT_URL;
                        } else {
                            window.location.href = DEFAULT_REDIRECT_URL;
                        }

                        // reject to prevent state from loading before the redirect happens
                        return Promise.reject(err);
                    }),
            accountData: (db, currentUser) => {
                if (!currentUser || !currentUser.accountId) {
                    return [];
                }

                return db.Account.find(currentUser.accountId, {
                    bypassCache: true
                });
            },
            locationAccess: ($window, accountData, $localStorage, currentUser, csiSettings) => {
                if (!currentUser && csiSettings) {
                    return {permission: false};
                }

                if (!accountData.settings.fsSettings.useLocation) {
                    return Promise.resolve({disabled: true});
                } else if ($localStorage.useLocation) {
                    // skip check, already accepted in this browser
                    return Promise.resolve({permission: true});
                }

                return new Promise((resolve) => {
                    $window.navigator.geolocation.getCurrentPosition(
                        () => {
                            $localStorage.useLocation = true;
                            resolve({permission: true});
                        },
                        () => resolve({permission: false})
                    );
                });
            },
            caseNumber: () => getRootStore().urlUtils.getParamValue('caseNumber')
        }
    });
}

export default angular
    .module('states.start', [mainModule.name, centerModule.name, expertModule.name, inviteModule.name])
    .config(config)
    .controller('StartController', StartController);
