import React from 'react';
import './nonBlockingSpinner.scss';
import Translate from '../Translate';

interface NonBlockingSpinnerProps {
    size?: number;
}

const NonBlockingSpinner: React.FC<NonBlockingSpinnerProps> = ({size = 100}) => {
    const spinnerStyle = {
        width: `${size}px`,
        height: `${size}px`,
        borderRadius: `${size}px`
    };

    return (
        <div className='non-blocking-spinner-container'>
            <div>
                <span className='non-blocking-spinner' style={spinnerStyle}></span>
            </div>
            <span className='title'>
                <Translate text='MEDIA_UPLOADING.VIEW.SENDING_IMAGE'></Translate>
            </span>
        </div>
    );
};

export default NonBlockingSpinner;
