import React, {useEffect, useRef, useState} from 'react';
import {GenericModal} from '@techsee/techsee-ui-common/lib/modals/generic';
import Translate from '../Translate';
import {requireImage} from '../../utils/utils';
import {Carousel} from 'react-responsive-carousel';
import ZoomableImage from '../zoomable-image/ZoomableImage';
import {getRootStore} from '../../app.bootstrap';
import {useAutoFocus} from '../../hooks/useAutoFocus';

import './message.style.scss';

type MessageType = 'IMG-DB' | 'IMG-CL' | 'VID-DB' | 'VID-CL' | 'MSG-DB' | 'MSG-CL';

const TRANSITION_TIME = 500;

interface Message {
    type: MessageType;
    data: string;
    video?: string;
    appStoreLink?: boolean;
    isNew?: boolean;
    alt?: string;
}

interface MessageModalProps {
    messages: Message[];
    initialPhotoIndex?: number;
    onClose: () => void;
}

export const MessageModal: React.FC<MessageModalProps> = ({messages, initialPhotoIndex = 0, onClose}) => {
    const [activeSlideIndex, setActiveSlideIndex] = useState<number>(initialPhotoIndex);
    const [isZoomed, setIsZoomed] = useState(false);
    const chatApi = getRootStore().chatApi;

    const isCurrentType = (type: MessageType): boolean => {
        return messages && messages.length > activeSlideIndex && messages[activeSlideIndex].type === type;
    };

    const getTextByCurrentType = (): string => {
        const {type} = messages[activeSlideIndex];

        switch (type) {
            case 'IMG-DB':
            case 'IMG-CL':
            case 'VID-DB':
            case 'VID-CL':
                return 'MESSAGE.VIEW.ITEM_TITLE';
            case 'MSG-CL':
            case 'MSG-DB':
                return 'MESSAGE.VIEW.TEXT_TITLE';
            default:
                return '';
        }
    };

    const handleToggleZoom = () => {
        setIsZoomed(!isZoomed);
    };

    const header = () => {
        return (
            <div className={'modal-header ts-modal-nav-bar'}>
                <h2 className={'sr-only'}>
                    <Translate text={'MESSAGE.VIEW.GALLERY'}></Translate>
                </h2>
                <h3 className={'modal-title'} aria-live={'polite'}>
                    {<Translate text={getTextByCurrentType()} />}
                    <span className={'modal-title-text'}>
                        {' '}
                        {activeSlideIndex + 1} <Translate text={'MESSAGE.VIEW.OF'} /> {messages.length}
                    </span>
                    {(isCurrentType('IMG-DB') || isCurrentType('IMG-CL')) && (
                        <button
                            className={'pull-right btn button-white'}
                            aria-live={'polite'}
                            type={'button'}
                            onClick={handleToggleZoom}>
                            <img src={requireImage('zoom-icon.svg')} alt='zoom in or out' />
                        </button>
                    )}
                </h3>
            </div>
        );
    };

    const content = () => {
        return (
            <React.Fragment>
                <Carousel
                    selectedItem={activeSlideIndex}
                    showThumbs={false}
                    showStatus={false}
                    infiniteLoop={true}
                    className={'carousel'}
                    onChange={(index: number) => {
                        setTimeout(() => {
                            setActiveSlideIndex(index);
                        }, TRANSITION_TIME);
                    }}
                    transitionTime={TRANSITION_TIME}
                    showIndicators={false}>
                    {messages.map((message, index) => (
                        <React.Fragment key={index}>
                            {message.type !== 'MSG-DB' && message.type !== 'MSG-CL' && (
                                <ZoomableImage
                                    isNew={message.isNew as boolean}
                                    src={message.data}
                                    type={message.type}
                                    isZoomed={isZoomed}
                                    imageLoadStarted={chatApi.imageLoadStarted}
                                    imageLoaded={chatApi.imageLoaded}
                                    imageLoadFailed={chatApi.imageLoadFailed}
                                />
                            )}
                            {(message.type === 'MSG-DB' || message.type === 'MSG-CL') && (
                                <div
                                    className='meeting-modal-text-message'
                                    dangerouslySetInnerHTML={{__html: message.data}}
                                />
                            )}
                        </React.Fragment>
                    ))}
                </Carousel>
            </React.Fragment>
        );
    };

    const footer = () => {
        const closeButtonRef = useRef<HTMLButtonElement>(null);

        useAutoFocus(closeButtonRef, 500);

        return (
            <div className='modal-footer ts-modal-nav-bar'>
                <button className='btn button-white' ref={closeButtonRef} type='button' onClick={onClose}>
                    <Translate text='MESSAGE.VIEW.CLOSE'></Translate>
                </button>
            </div>
        );
    };

    return (
        <React.Fragment>
            <GenericModal
                isCustomPosition={true}
                className={'custom-position meeting-message-modal'}
                header={header}
                content={content}
                toolbar={footer}
                isCloseVisible={false}
                onHideHandler={() => {}}
                show={true}></GenericModal>
        </React.Fragment>
    );
};
