'use strict';

import get from 'lodash/get';
import {getRootStore} from '../../app.bootstrap';
import {STATUS_MESSAGES} from '../../../states/meeting/meeting.settings';
import {PromiseUtilsService} from '@techsee/techsee-client-services/lib/services/PromiseUtilsService';
import {getMeetingTracer} from '../../../states/meeting/meeting.tracer';
import {action, computed, observable} from 'mobx';
import {MobileChatApiService} from '../../services/ts-chat-api/MobileChatApi';
import {IUrlUtilsService} from '../../services/ts-url-utils/ts-url-utils.service';
import {ITsEventService} from '../../services/ts-event-service/ts-event-service';
const trace = getMeetingTracer('TsChatHelperService');

export interface ITsEndMeetingController {
    init(styleButton: boolean): void;
    pop(): Promise<any>;
    ok(): Promise<any>;
    cancel(): void;
    finishMeeting(): Promise<void>;
    isModalOpen: boolean;
    isNewStyle: boolean;
}

export class TsEndMeetingController implements ITsEndMeetingController {
    private chatApi: any;
    private endParams: {csi?: string};
    private EventService: any;

    @observable private _isModalOpen: boolean = false;
    @observable private _isNewStyle: boolean = false;

    constructor(chatApi: MobileChatApiService, urlUtils: IUrlUtilsService, eventService: ITsEventService) {
        this.chatApi = chatApi;
        const csi = urlUtils.getParamValue('csi');
        this.endParams = csi ? {csi} : {};
        this.EventService = eventService;

        this.cancel = this.cancel.bind(this);
        this.ok = this.ok.bind(this);
        this.pop = this.pop.bind(this);
    }

    @computed
    get isModalOpen(): boolean {
        return this._isModalOpen;
    }

    @computed
    get isNewStyle(): boolean {
        return this._isNewStyle;
    }

    @action
    init(isNewStyle: boolean) {
        this._isNewStyle = isNewStyle && get(this.chatApi, 'accountSettings.showEndMeetingButton');
    }

    @action
    async pop() {
        this.chatApi.sendLog(STATUS_MESSAGES.END_MEETING_POP_UP, new Date().getTime());
        this.EventService.sendEventLog('none', this.chatApi.roomId || 'none', STATUS_MESSAGES.END_MEETING_POP_UP);

        this._isModalOpen = true;
    }

    @action
    show(): Promise<any> {
        this._isModalOpen = true;

        return Promise.resolve();
    }

    finishMeeting(): Promise<void> {
        return Promise.resolve()
            .then(() => {
                this.chatApi.sendLog(STATUS_MESSAGES.CUSTOMER_ENDED_THE_MEETING);
                this.EventService.sendEventLog(
                    'none',
                    this.chatApi.roomId || 'none',
                    STATUS_MESSAGES.CUSTOMER_ENDED_THE_MEETING
                );
                this.chatApi.disconnect(get(this.chatApi, 'accountSettings.customerEndSessionWithoutClosingRoom'));
            })
            .then(() => {
                this.chatApi.unlock();
                const tracePromise = () => trace.info('Photo controller end meeting by customer: ');

                return PromiseUtilsService.startPromiseWithTimeout(tracePromise, 3000).then(() => {
                    return getRootStore().stateHelper.safeGo('endNew', this.endParams);
                });
            })
            .catch(() => {
                this.chatApi.unlock();
            });
    }

    @action
    async ok() {
        this._isModalOpen = false;

        await this.finishMeeting();
    }

    @action
    cancel() {
        this._isModalOpen = false;
        this.chatApi.sendLog(STATUS_MESSAGES.END_MEETING_CANCELLED, new Date().getTime());
        this.EventService.sendEventLog(
            'none',
            this.chatApi.roomId || 'none',
            STATUS_MESSAGES.END_MEETING_CANCELLED,
            'Customer selected to continue the session'
        );
    }
}
