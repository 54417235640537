import React from 'react';
import {Carousel} from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './reactResponsiveCarouselWrapper.scss';
import {Message} from '../ts-fullscreen-gallery/FullscreenGallery.controller';

interface ReactResponsiveCarouselWrapperProps {
    activeMessage: number;
    messages: Message[];
    showIndicators?: boolean;
}

export const ReactResponsiveCarouselWrapper: React.FC<ReactResponsiveCarouselWrapperProps> = ({
    activeMessage,
    messages,
    showIndicators = false
}) => {
    return (
        <div className='ts-fullscreen-gallery'>
            <Carousel
                selectedItem={activeMessage}
                showThumbs={false}
                showStatus={false}
                infiniteLoop={false}
                className={'carousel'}
                showIndicators={showIndicators}>
                {messages.map((message, index) => (
                    <div key={index} className='ts-carousel-item'>
                        <img src={message.data} className='fs-gallery-image' alt='' />
                        {message.setId && (
                            <div className='step-container'>
                                <span className='step-display'>
                                    {`Step ${message.stepIndex} of ${message.stepCount}`}
                                </span>
                            </div>
                        )}
                    </div>
                ))}
            </Carousel>
        </div>
    );
};
