import React from 'react';
import {supportedThemes} from '@techsee/techsee-common/lib/constants/account.constants';
import {requireImage} from '../../utils/utils';
import isEmpty from 'lodash/isEmpty';

import './ts-busy-background.style.scss';

interface IBusyBackgroundProps {
    theme?: string;
    logoWatermarkImage?: string;
}

export const BusyBackground: React.FC<IBusyBackgroundProps> = ({theme, logoWatermarkImage}) => {
    const themPath = theme + '/watermark-photo.png';

    return (
        <div className={`ts-busy-background-container ${theme}`}>
            <div className='ts-busy-background-watermark'>
                {(isEmpty(theme) || theme === supportedThemes.in) && (
                    <img src={logoWatermarkImage || requireImage('techsee-watermark.png')} alt='TechSee' />
                )}
                {!isEmpty(theme) && theme !== supportedThemes.in && (
                    <img src={logoWatermarkImage || requireImage(themPath)} alt='branding' />
                )}
            </div>
        </div>
    );
};
