import React from 'react';
import {observer} from 'mobx-react';
import {getRootStore} from '../../app.bootstrap';
import {TsPopup} from '../ts-popup/TsPopup';
import {GenericModal} from '@techsee/techsee-ui-common/lib/modals/generic';
import Translate from '../Translate';

import './endMeetingConfirmation.scss';
import {TechseeBaseComponent} from '@techsee/techsee-ui-common/lib/_shared/techsee-base-component';

@observer
export class EndMeetingConfirmation extends TechseeBaseComponent {
    renderInternal() {
        const controller = getRootStore().endMeetingConfirmationController;

        if (!controller.isModalOpen) {
            return <React.Fragment></React.Fragment>;
        }

        if (!controller.isNewStyle) {
            return (
                <div>
                    <GenericModal
                        isCustomPosition={true}
                        className={`${this.renderCssClasses()} custom-position end-meeting-modal`}
                        header={() => <React.Fragment></React.Fragment>}
                        content={() => (
                            <React.Fragment>
                                {' '}
                                <div className='modal-body-up text-center' role='alert'>
                                    <div className='row'>
                                        <Translate text='END_MEETING.VIEW.WOULD_YOU_LIKE_TO'></Translate>
                                    </div>
                                </div>
                                <div className='modal-body text-center' role='alert'>
                                    <div className='row'>
                                        <button type='button' className='btn-default' onClick={controller.ok}>
                                            <Translate text='END_MEETING.VIEW.LEAVE_SESSION'></Translate>
                                        </button>
                                    </div>
                                    <div className='row'>
                                        <button type='button' className='btn-default' onClick={controller.cancel}>
                                            <Translate text='END_MEETING.VIEW.CONTINUE_SESSION'></Translate>
                                        </button>
                                    </div>
                                </div>
                            </React.Fragment>
                        )}
                        toolbar={() => <React.Fragment></React.Fragment>}
                        isCloseVisible={false}
                        onHideHandler={() => {}}
                        show={true}></GenericModal>
                </div>
            );
        }

        return (
            <div>
                <TsPopup
                    popupWidth={300}
                    isModal={true}
                    btnOk={'END_MEETING.VIEW.NEW.LEAVE_SESSION'}
                    btnCancel={'END_MEETING.VIEW.NEW.CONTINUE_SESSION'}
                    imgSrc={'img/popup_alert.png'}
                    onClickedOk={controller.ok}
                    onClickedCancel={controller.cancel}
                    sideLabel={'END_MEETING.VIEW.NEW.WOULD_YOU_LIKE_TO'}></TsPopup>
            </div>
        );
    }
}
