'use strict';

import get from 'lodash/get';
import forEach from 'lodash/forEach';
import findIndex from 'lodash/findIndex';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import {speedtestUrl} from '@techsee/techsee-common/lib/constants/account.constants.js';
import {FlowType, UserType} from '@techsee/techsee-common/lib/constants/room.constants';

import {
    STATUS_MESSAGES,
    PHOTO_SELECTION_STATE,
    ROOM_MODES,
    VIEW_PAGES,
    MEDIA_COMPLETION_POPUP_TIMEOUT
} from '../meeting.settings.js';
import {MeetingStateControllerBase} from '../meeting.state.controller.base';
import {MeetingState} from '@techsee/techsee-common/lib/constants/meeting.states.definition';
import {getMeetingTracer} from '../meeting.tracer';
import {PromiseUtilsService} from '@techsee/techsee-client-services/lib/services/PromiseUtilsService';
import * as socketEvents from '@techsee/techsee-common/lib/socket/client';
import {getRootStore} from '../../../_react_/app.bootstrap';

const trace = getMeetingTracer('PhotoChatController');

export class PhotoChatController extends MeetingStateControllerBase {
    constructor(
        $localStorage,
        $sessionStorage,
        $rootScope,
        $scope,
        $stateParams,
        $uibModal,
        $window,
        $sce,
        uploadSessionAssetsModal,
        db,
        currentUser,
        mobileAppMediaService
    ) {
        'ngInject';

        super($rootScope, $scope, MeetingState.PhotoChat, db, getRootStore().eventService);

        this.stateHelper = getRootStore().stateHelper;
        this.$scope = $scope;
        this.$window = $window;
        this.$sce = $sce;
        this.$uibModal = $uibModal;
        this.messageHistory = getRootStore().chatHelper.messageHistory;
        this.tsBrowserUtilsService = getRootStore().browserUtilsService;
        this.tsAppstoreUrlUtils = getRootStore().appstoreUrlUtils;
        this.isIOS = getRootStore().environmentDetect.isIOS();
        this.needExternalCameraButton =
            getRootStore().environmentDetect.isAndroid() && getRootStore().environmentDetect.os()?.version >= '14.0';
        this.EndMeetingConfirmationModal = getRootStore().endMeetingConfirmationController;
        this.uploadSessionAssetsModal = uploadSessionAssetsModal;
        this.imageFixer = getRootStore().imageFixer;
        this.videoFixer = getRootStore().videoFixer;
        // viewImageSrc is the image displayed on the overlay
        this.viewImageSrc = null;
        // Outgoing image url
        this.photoSrc = null;
        this.videoSrc = null;

        this.chatApi = getRootStore().chatApi;
        this.chatHelper = getRootStore().chatHelper;

        this.mobileAppMediaService = mobileAppMediaService;
        this.db = db;
        this.endParams = $stateParams.csi ? {csi: $stateParams.csi} : {};
        this.showMediaButton = true;

        this.theme = getRootStore().theme;
        this.watermarkClass = this._getWatermarkClass();
        this.firstTapOnCamera = true;
        this.tsTermsAndConditions = getRootStore().termsAndConditionsController;
        this.endParams = $stateParams.csi ? {csi: $stateParams.csi} : {};
        this.$localStorage = $localStorage;
        this.$sessionStorage = $sessionStorage;
        this.testUpload = getRootStore().testUpload;
        this.currentRole = currentUser && currentUser.role;
        this.VIEW_PAGES = VIEW_PAGES;
        this.runSpeedTestOnClient = this.$sessionStorage.runSpeedTestOnClient;
        this.chatApi.setStatus(
            socketEvents.CLIENT_OUT_SET_STATUS.PHOTO_SELECTION_DIALOG_STATE,
            PHOTO_SELECTION_STATE.IN_PHOTO_CHAT
        );

        this.endMeetingButtonAria = getRootStore().localizationService.translate('END.VIEW.END_MEETING_BUTTON_ARIA');
        this.endMeetingModalService = getRootStore().endMeetingConfirmationController;
        this.onClickEndMeetingButton = this.endMeetingModalService.pop;

        this.chooseFromGalleryButtonAria = getRootStore().localizationService.translate(
            'IMAGE_UPLOAD.VIEW.CHOOSE_FROM_GALLERY_BUTTON_ARIA'
        );

        this._brandingService = getRootStore().brandingService;

        this.mobileBranding = this._brandingService.getBrandingData();

        this.imageUploadTranslate = getRootStore().localizationService.translate(
            'IMAGE_UPLOAD.VIEW.ENTER_TEXT_MESSAGE'
        );

        this.$scope.$on('$viewContentLoaded', () => {
            // make the code execute on the next cycle
            setTimeout(() => {
                this.tsEventService.sendEventLog(
                    'none',
                    this.chatApi.roomId || 'none',
                    STATUS_MESSAGES.CLIENT_IMAGE_UPLOAD_VIEW_LOADED
                );
            }, 0);
        });

        this._onHistoryMessageReceived = this._onHistoryMessageReceived.bind(this);
        this.onCloseMessageModal = this.onCloseMessageModal.bind(this);

        this.$scope.$watch(
            () => get(this.chatApi, 'connected'),
            () => this.enableNoInternetMode && this.sendPendingMessages()
        );

        this.openedCamera = this.openedCamera.bind(this);
        this.loadStarted = this.loadStarted.bind(this);
        this.loadAborted = this.loadAborted.bind(this);
        this.sendPhoto = this.sendPhoto.bind(this);
        this.inputCreatedCallback = this.inputCreatedCallback.bind(this);

        this.notifyConstructionIsReady();
    }

    onCloseMessageModal() {
        this.photoIndex = undefined;
        this.chatApi.sendLog(STATUS_MESSAGES.CUSTOMER_CLOSED_PHOTOGALLERY_PAGE);
        this.tsEventService.sendEventLog(
            'none',
            this.chatApi.roomId || 'none',
            STATUS_MESSAGES.CUSTOMER_CLOSED_PHOTOGALLERY_PAGE
        );
    }

    //#region Meeting Pipeline

    get handshakeFailureStatusMessage() {
        return STATUS_MESSAGES.PHOTO_STREAM_FAILED;
    }

    meetingModeHandshake() {
        this._readAccountSettings();
        if (this.displayTermsInPhotoStream) {
            return this.tsTermsAndConditions
                .syncTOS(this.chatApi, ROOM_MODES.images, this.currentRole)
                .then((isApproved) => {
                    if (isApproved) {
                        this.chatHelper.setMode();
                        this.tosAccepted = true;
                        this.chatApi.setStatus(socketEvents.CLIENT_OUT_SET_STATUS.IS_REVIEWING_TOS, false);

                        this.chatApi.sendLog(STATUS_MESSAGES.CONNECTED_IN_IMAGE_UPLOAD_MODE);
                        this.chatApi.cameraApprovalDialogStateChange(false);
                    }
                });
        }

        return Promise.resolve();
    }

    syncMeetingState() {
        if (this.initialized) {
            return Promise.resolve();
        }

        const mobileSpeedtestDefaultProvider = get(this.chatApi, 'accountSettings.mobileSpeedtestDefaultProvider');

        this.initialized = true;
        this.speedtestUrl = mobileSpeedtestDefaultProvider
            ? speedtestUrl.ookla
            : get(this.chatApi, 'accountSettings.mobileSpeedtestUrl');
        this.SPEEDTEST_URL = this.$sce.trustAsResourceUrl(this.speedtestUrl);

        this.showPhotoTakingGuidance = this.chatApi.client.showPhotoTakingGuidance;
        this.chatApi.setStatus(socketEvents.CLIENT_OUT_SET_STATUS.IS_ON_SCREEN_SHARE_TURN_OFF_GUIDANCE_IOS, false);
        this.chatApi.setStatus(socketEvents.CLIENT_OUT_SET_STATUS.SCREEN_SHARE_CAPTURING_APPROVED_IOS, false);
        this.chatApi.setStatus(socketEvents.CLIENT_OUT_SET_STATUS.IMAGE_UPLOAD_HANDSHAKE_SUCCESS, true);
        this.chatApi.setStatus(socketEvents.CLIENT_OUT_SET_STATUS.MODE, ROOM_MODES.images);

        this.db.Rooms.createInstance({_id: this.chatApi.roomId}).changeAgent(ROOM_MODES.images);

        if (this.chatApi.enableSilentUploadTest) {
            this.testUpload.exec(this.chatApi.roomId, this.chatApi.imageSharingQuality === 1);
        }

        this.chatApi.setStatus(socketEvents.CLIENT_OUT_SET_STATUS.IS_REVIEWING_TOS, false);

        if (!this.chatStart()) {
            this.chatApi.sendLog(STATUS_MESSAGES.WAITING_FOR_TAP_THE_CAMERA_BUTTON);
            this.tsEventService.sendEventLog(
                'none',
                this.chatApi.roomId || 'none',
                STATUS_MESSAGES.WAITING_FOR_TAP_THE_CAMERA_BUTTON
            );
        }

        this.chatApi.setStatus(
            socketEvents.CLIENT_OUT_SET_STATUS.PHOTO_SELECTION_DIALOG_STATE,
            PHOTO_SELECTION_STATE.IN_PHOTO_CHAT
        );

        this._openCameraAfterTermsIfNeeded();
    }

    initModeHandlers() {
        const runSpeedTestListener = (value) => this._showSpeedtestPage(value);

        this.chatApi.on(socketEvents.CLIENT_IN.RUN_SPEED_TEST_ACTION, runSpeedTestListener);
        this.chatApi.on(socketEvents.CLIENT_IN.NEW_HISTORY_MESSAGE_RECEIVED, this._onHistoryMessageReceived);
        this.endMeetingModalService.init(false);
        this.isNewEndMeetingButton = this.endMeetingModalService.isNewStyle;

        this.$scope.$on('$stateChangeStart', (e, toState, toParams, fromState, fromParams, options) => {
            // close the uploading modal before moving to video/ending a session
            const uploadDialogClosed = this.chatHelper.setIsSendingMedia(false);

            if (uploadDialogClosed) {
                e.preventDefault();

                this.tsEventService
                    .sendEventLog('none', this.chatApi.roomId || 'none', STATUS_MESSAGES.IMAGE_UPLOAD_CANCELLED)
                    .finally(() => this.stateHelper.safeGo(toState.name, toParams, options));
            }
        });

        this.$scope.$on('$destroy', () => {
            this.chatHelper.closePopups();

            if (this.modalInstance) {
                this.modalInstance.close();
            }

            this.chatApi.setStatus(socketEvents.CLIENT_OUT_SET_STATUS.PHOTO_SELECTION_DIALOG_STATE, null);
            this.chatApi.off(socketEvents.CLIENT_IN.RUN_SPEED_TEST_ACTION, runSpeedTestListener);
            this.chatApi.off(socketEvents.CLIENT_IN.NEW_HISTORY_MESSAGE_RECEIVED, this._onHistoryMessageReceived);
        });
    }

    //#endregion Meeting Pipeline

    termsToggle(visible) {
        this.termsWindowOpen = visible;
    }

    _getWatermarkClass() {
        if (this.theme === 'vgr') {
            return 'ts-photo-logo vgr-photo-logo';
        }

        return 'ts-photo-logo';
    }

    _readAccountSettings() {
        const tosAcceptedRoom = this.tsBrowserUtilsService.getFromLocalStorage('tos_accepted'),
            tosAccepted = tosAcceptedRoom === this.chatApi.roomId;

        const accountSettings = this.chatApi.accountSettings;

        if (accountSettings) {
            this.displayTerms = accountSettings.displayTerms && !tosAccepted;

            const displayAccountTerms = accountSettings.displayAccountTerms;
            const displayTechseeTerms = accountSettings.displayTechseeTerms;
            const displayPrivacyTerms = accountSettings.displayPrivacyTerms;

            this.termsURL = displayTechseeTerms && accountSettings.termsURL;
            this.accountTermsUrl = displayAccountTerms && accountSettings.accountTermsUrl;
            this.privacyURL = displayPrivacyTerms && accountSettings.privacyURL;

            this.companyName = accountSettings.companyName;
            this.requestEndMeetingConfirmation = accountSettings.requestEndMeetingConfirmation;
            this.allowClientChat = accountSettings.allowClientChat;
            this.displayTermsInPhotoStream = accountSettings.displayTermsInPhotoStream;
            this.enableMobileSpeedtest = get(this.chatApi, 'accountSettings.enableMobileSpeedtest');
            this.enableNoInternetMode =
                get(this.chatApi, 'accountSettings.enableNoInternetMode') &&
                get(this.chatApi, 'client.flowType') === FlowType.fs;

            this.videoSettings = {
                allowUploadVideoClips: accountSettings.allowUploadVideoClips,
                storedVideoMaxSizeInMb: accountSettings.storedVideoMaxSizeInMb
            };

            this.newImageUploadUI = get(this.chatApi, 'accountSettings.enableNewImageUploadOnMobile');
        }
    }

    sendPendingMessages() {
        const messages = [];

        if (this.chatHelper.messageHistory.pendingMessagesCount === 0 || !get(this.chatApi, 'connected')) {
            return;
        }

        this.uploadSessionAssetsModal
            .show()
            .then((uploadSessionMessage) => {
                if (!uploadSessionMessage) {
                    return;
                }

                if (get(this.chatApi, 'connected')) {
                    forEach(this.chatHelper.messageHistory.pendingMessages, (message) => {
                        if (message.isVideo) {
                            messages.push(() =>
                                this.sendVideo(message.video, true).then(() => {
                                    message.pending = false;
                                })
                            );
                        } else if (message.type === 'MSG-CL' || message.type === 'MSG-DB') {
                            messages.push(() =>
                                this.sendTextMessage({textMessage: message.data}).then(() => {
                                    message.pending = false;
                                })
                            );
                        } else {
                            messages.push(() =>
                                this.sendPhoto(message.data, true).then(() => {
                                    message.pending = false;
                                })
                            );
                        }
                    });

                    return messages
                        .reduce((promiseChain, currentTask) => promiseChain.then(currentTask), Promise.resolve())
                        .then(() => this.displaySentSuccessfully());
                }
            })
            .catch(() => false);
    }

    displaySentSuccessfully() {
        this.isAllPedingMessagesSent = true;

        return setTimeout(() => {
            this.isAllPedingMessagesSent = false;
        }, MEDIA_COMPLETION_POPUP_TIMEOUT);
    }

    get isSendingMedia() {
        return this.chatHelper.isSendingMedia;
    }

    isChatConnectVisible() {
        return this.chatApi.offlineRoom && get(this.messageHistory, 'messages.length') && !this.areBothSidesConnected;
    }

    _notifyMessageReceived(msgIndex, isVideo, isImage) {
        if (isImage || isVideo) {
            if (this.modalInstance) {
                // if the modal is already open, we close it and reopen it with the new data
                this.modalInstance.close({reopen: true, newIndexToFocusOn: msgIndex - 1});
            } else {
                this.viewImage(msgIndex - 1);
            }
        }

        // Updates the text in a way that will be read by screen-readers
        this.newMessageAnnounce = `${getRootStore().localizationService.translate('IMAGE_UPLOAD.VIEW.NEW_MESSAGE')}: ${this.messageHistory.messages.length}`;
    }

    _showSpeedtestPage(value) {
        this.runSpeedTestOnClient = true;
        this.$sessionStorage.runSpeedTestOnClient = this.runSpeedTestOnClient;

        if (value) {
            this.chatHelper.setPage(VIEW_PAGES.SPEEDTEST);
        }
    }

    displaySpeedtestButton() {
        return this.enableMobileSpeedtest && this.runSpeedTestOnClient && this.chatHelper.isInMainPage;
    }

    /*
     * Takes a message from either the server or the client itself and
     * pushes it in the message list, after processing it to
     * be 'friendlier' to the view.
     *
     * @param msg - Incoming message
     */
    _onHistoryMessageReceived(newHistoryMessageData) {
        const {isPartialSet, firstMessageInSet, message} = newHistoryMessageData;

        this.messageHistory = this.chatHelper.messageHistory;

        if (isPartialSet && isPartialSet !== this.chatHelper.PARTIAL_SET_STATE.COMPLETE) {
            return;
        } else if (
            isPartialSet === this.chatHelper.PARTIAL_SET_STATE.COMPLETE &&
            firstMessageInSet &&
            firstMessageInSet.isNew
        ) {
            this._notifyMessageReceived(firstMessageInSet.index, firstMessageInSet.isVideo, !firstMessageInSet.isVideo);

            return;
        }

        const isImage = message.data.type === 'image';
        const isVideo = message.data.type === 'video';

        if (
            message.isNew &&
            message.sender === UserType.client &&
            (isImage || isVideo) &&
            this.chatApi.client.photoSelectionDialogState === PHOTO_SELECTION_STATE.IN_CAMERA_DIALOG
        ) {
            this.chatApi.setStatus(socketEvents.CLIENT_OUT_SET_STATUS.PHOTO_SELECTION_DIALOG_STATE, null);
        }

        if (message.sender === UserType.dashboard && message.isNew) {
            const msgIndex = findIndex(
                this.messageHistory.messages,
                (historyMessage) => historyMessage.storageIndex === message.storageIndex
            );

            // Notify the server that the client got the message
            this._notifyMessageReceived(
                msgIndex < 0 ? this.messageHistory.messages.length : msgIndex + 1,
                isVideo,
                isImage
            );
        }
    }

    _getPhotoSelectionState(cameraDialogDisplayed) {
        if (find(this.messageHistory.messages, (message) => message.type === 'IMG-CL')) {
            return;
        }

        if (cameraDialogDisplayed) {
            return PHOTO_SELECTION_STATE.IN_CAMERA_DIALOG;
        }

        return PHOTO_SELECTION_STATE.IN_PHOTO_CHAT;
    }

    /*
     * Check if there is any message in the list.
     * Determines if the initial overlay is visible
     */
    chatStart() {
        return !this.messageHistory.messages.length;
    }

    /**
     * Show the zoomed image overlay
     * Index is image index to be opened, optional, none provided will open last image
     */
    viewImage(index) {
        this.chatApi.sendLog(STATUS_MESSAGES.CUSTOMER_IN_PHOTOGALLERY_PAGE);
        this.tsEventService.sendEventLog(
            'none',
            this.chatApi.roomId || 'none',
            STATUS_MESSAGES.CUSTOMER_IN_PHOTOGALLERY_PAGE
        );
        this.photoIndex = index;
    }

    _wasInPhotoStreamMode(isFirstTime) {
        if (!isFirstTime) {
            this.firstTapOnCamera = false;

            if (this.showPhotoTakingGuidance) {
                this.showPhotoTakingGuidance = false;
                this.chatApi.setStatus(socketEvents.CLIENT_OUT_SET_STATUS.SHOW_PHOTO_TAKING_GUIDANCE, false);
            }
        }
    }

    _sendPhotoToServer(objUrl, origin, shouldConfirm, retryIndex, isPending) {
        const sendMediaOptions = {
            photoSrc: objUrl,
            origin: origin,
            withConfirm: shouldConfirm,
            withLoader: this.newImageUploadUI,
            retryIndex: retryIndex,
            isPending
        };

        return this.chatHelper
            .sendMedia(sendMediaOptions)
            .then(() => {
                this.tsBrowserUtilsService.saveToLocalStorage('tos_accepted', this.chatApi.roomId);
                this.displayTerms = false;
                this._wasInPhotoStreamMode();
            })
            .catch(() => {
                this.chatHelper.setIsSendingMedia(false);
                // Refresh message history as a failed message is added in some cases
                this.messageHistory = this.chatHelper.messageHistory;
            });
    }

    _sendVideoToServer(objUrl, origin, shouldConfirm, isPending) {
        const sendMediaOptions = {
            videoSrc: objUrl,
            origin: origin,
            withConfirm: shouldConfirm,
            withLoader: this.newImageUploadUI,
            isPending
        };

        return this.chatHelper
            .sendMedia(sendMediaOptions)
            .then(() => {
                this.tsBrowserUtilsService.saveToLocalStorage('tos_accepted', this.chatApi.roomId);
                this.displayTerms = false;
                this._wasInPhotoStreamMode();
            })
            .catch(() => {
                this.chatHelper.setIsSendingMedia(false);
            });
    }

    sendPhoto(objUrl, isPending) {
        return this._sendPhotoToServer(objUrl, ROOM_MODES.images, true, null, isPending);
    }

    sendVideo(objUrl, isPending) {
        return this._sendVideoToServer(objUrl, ROOM_MODES.images, true, isPending);
    }

    retryImageUpload(index, event) {
        event.stopPropagation();

        this._sendPhotoToServer(this.messageHistory.messages[index].data, ROOM_MODES.images, false, index);
    }

    inputCreatedCallback(input) {
        if (this.theme !== 'of' || this.$localStorage.wasInVideoMode) {
            return;
        }

        if (input && input.id === 'imageInputLoader') {
            this.cameraButton = input;

            this._openCameraAfterTermsIfNeeded();
        }
    }

    _openCameraAfterTermsIfNeeded() {
        if (!this.cameraButton) {
            return;
        }

        if (this.firstTapOnCamera && this.displayTermsInPhotoStream && this.tosAccepted) {
            this.openedCamera(true);
            this.cameraButton.click();
        }
    }

    /**
     * Sends status when camera is open
     */
    openedCamera(isFirstTime) {
        // Set TOS as accepted
        this.tsBrowserUtilsService.saveToLocalStorage('tos_accepted', this.chatApi.roomId);
        this.displayTerms = false;
        this._wasInPhotoStreamMode(isFirstTime);

        this.chatApi.suppressVisibilityChanged();
        this.chatApi.sendLog(STATUS_MESSAGES.CUSTOMER_OPENED_THE_CAMERA);
        this.tsEventService.sendEventLog(
            'none',
            this.chatApi.roomId || 'none',
            STATUS_MESSAGES.CUSTOMER_OPENED_THE_CAMERA
        );
        this.chatApi.setStatus(
            socketEvents.CLIENT_OUT_SET_STATUS.PHOTO_SELECTION_DIALOG_STATE,
            this._getPhotoSelectionState(true)
        );
    }

    loadStarted() {
        this.chatApi.sendLog(STATUS_MESSAGES.CUSTOMER_CLOSED_THE_CAMERA);
        this.tsEventService.sendEventLog(
            'none',
            this.chatApi.roomId || 'none',
            STATUS_MESSAGES.CUSTOMER_CLOSED_THE_CAMERA
        );
    }

    loadAborted() {
        this.chatHelper.setIsSendingMedia(false);
    }

    sendTextMessage(form) {
        if (!form.textMessage) {
            return;
        }

        if (!this.chatApi.connected) {
            this.chatHelper.pendingMessageText(form.textMessage);

            form.textMessage = '';

            return;
        }

        this.chatApi.sendText(form.textMessage);
        this.tsBrowserUtilsService.saveToLocalStorage('tos_accepted', this.chatApi.roomId);
        this.displayTerms = false;
        this._wasInPhotoStreamMode();
        form.textMessage = '';

        return Promise.resolve();
    }

    toggleMediaButtons(value) {
        this.showMediaButton = value;
    }

    showMediaButtons() {
        return this.showMediaButton || isEmpty(this.sendTextForm.textMessage);
    }

    showImageUploadSuccessNotification() {
        return this.chatHelper.showImageUploadSuccess;
    }

    showImageUploadFailureNotification() {
        return this.chatHelper.showImageUploadFailure;
    }

    showVideoUploadSuccessNotification() {
        return this.chatHelper.showVideoUploadSuccess;
    }

    showVideoUploadFailureNotification() {
        return this.chatHelper.showVideoUploadFailure;
    }

    /**
     * End meeting
     */
    endMeeting() {
        this.EndMeetingConfirmationModal.show()
            .then(() => {
                this.chatApi.sendLog(STATUS_MESSAGES.CUSTOMER_ENDED_THE_MEETING);
                this.tsEventService.sendEventLog(
                    'none',
                    this.chatApi.roomId || 'none',
                    STATUS_MESSAGES.CUSTOMER_ENDED_THE_MEETING
                );
                this.chatApi.disconnect();
                const tracePromise = () => trace.info('Photo controller end meeting by customer: ');

                return PromiseUtilsService.startPromiseWithTimeout(tracePromise, 3000).then(() =>
                    this.stateHelper.safeGo('endNew', this.endParams)
                );
            })
            .catch(() => false);
    }

    getMessageBackground(message) {
        if (!message.isVideo) {
            return `url('${message.data}')`;
        }

        return `url('../../../../img/video-thumbnail.png'), url('${message.data}')`;
    }

    messageClicked(message) {
        if (!message.appStoreLink) {
            return;
        }

        this.tsAppstoreUrlUtils.prepareAppstoreRedirect();
    }

    goToState(state) {
        this.stateHelper.safeGo(state);
    }

    get areBothSidesConnected() {
        return this.chatApi.areBothSidesConnected;
    }
}
