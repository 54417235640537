'use strict';

// Load external libraries (JS and CSS)
import vendorModule from './vendor/vendor.module.js';
import {LOADER_STATES} from '../loader/loader.settings.js';
import includes from 'lodash/includes';

// Load app modules
import componentsModule from './components/components.module.js';
import directivesModule from './directives/directives.module.js';
import filtersModule from './filters/filters.module.js';
import servicesModule from './services/services.module.js';
import constantsModule from './constants/constants.module.js';
import statesModule from './states/states.module.js';

// Sentry
import './error-reporting';

// App assets
import 'styles/main.scss';
import {TechseeSocketManager} from '@techsee/techsee-client-infra/lib/infra/SocketManager';
import {
    enableTracerByName,
    registerTracerOutput,
    traceToConsoleOutputFunction
} from '@techsee/techsee-common/lib/core/Tracer';
import {TECHSEE_MEDIA_TRACER} from '@techsee/techsee-media-service/lib/MediaUtils/MediaTracer';
import {MEETING_TRACER} from './states/meeting/meeting.tracer';
import {supportedThemes} from '@techsee/techsee-common/lib/constants/account.constants';
import {getBrowserInfo} from 'shared/browser-info.js';
import {getRootStore, reactAppBootstrap} from './_react_/app.bootstrap';
import startsWith from 'lodash/startsWith';
import {PlatformType} from '@techsee/techsee-common/lib/constants/utils.constant';
import {STATUS_MESSAGES} from './states/meeting/meeting.settings';

function config(
    $stateProvider,
    $urlRouterProvider,
    $compileProvider,
    $httpProvider,
    $locationProvider,
    $windowProvider
) {
    $locationProvider.hashPrefix('');

    // Disable extra debug info
    if (ENV.production) {
        $compileProvider.debugInfoEnabled(false);
    }

    if (!ENV.dev) {
        $locationProvider.html5Mode({enabled: true, requireBase: false});
    }

    // polyfill needed for old Android browsers (<4.3)
    const $window = $windowProvider.$get();

    $window.URL = $window.URL || $window.webkitURL;

    // Set default URL entry point if none of the routes match
    // This will run on each unknown route
    $urlRouterProvider.otherwise(($injector) => {
        const stateHelper = getRootStore().stateHelper;
        const $location = $injector.get('$location');
        const urlParams = $location.search();

        stateHelper.safeGo('meeting', urlParams);
    });

    $httpProvider.interceptors.push('tokenInterceptor');

    $stateProvider.state('redirect', {
        url: '/redirect?uri',
        resolve: {
            doRedirect($stateParams, $window) {
                const uri = $stateParams.uri;

                $window.location.href = uri;
            }
        }
    });
}

function afterRun($localStorage, $rootScope, $location, $timeout, db, tsUrlConfig, $state, $http) {
    'ngInject';

    const locale =
        $localStorage.techseeClientLang ||
        getRootStore().browserUtilsService.getFromLocalStorage('techseeClientLang') ||
        'en_US';

    const safeApply = ($rootScope.safeApply = (fn) => {
        const phase = $rootScope.$$phase;

        if (phase === '$apply' || phase === '$digest') {
            if (angular.isFunction(fn)) {
                fn();
            }
        } else {
            $rootScope.$apply(fn);
        }
    });

    $localStorage.techseeClientLang = locale;
    getRootStore().browserUtilsService.saveToLocalStorage('techseeClientLang', locale);

    $rootScope.LOCALE = locale;
    $rootScope.LOCALE_DIR = includes(['ar_AR', 'he_IL'], locale) ? 'rtl' : 'ltr';

    $rootScope.BASE_PATH = BASE_PATH;

    $rootScope.requireImage = function (imageFileName, svgAsReactComponent) {
        const image = require('img/' + imageFileName);

        if (/\.svg$/.test(imageFileName)) {
            if (svgAsReactComponent) {
                return image.ReactComponent;
            }

            return image.default || image;
        }

        return image;
    };

    $rootScope.requireAudio = function (audioFileName) {
        return new Audio('audio/' + audioFileName);
    };

    $rootScope.THEME = $location.search().t !== supportedThemes.ts ? $location.search().t : '';

    // Disable gestures
    document.documentElement.addEventListener(
        'touchstart',
        (event) => {
            if (event.touches && event.touches.length > 1) {
                event.preventDefault();
            }
        },
        true
    );

    const socketInstance = configSocketManager(
        tsUrlConfig.get('SOCKET_URL'),
        getRootStore().browserUtilsService,
        getRootStore().eventService,
        getRootStore().roomChannelTracer
    );

    configureMobileAppTracing(getRootStore().eventService, $localStorage);

    // allow some time for first view to render, before hiding loader
    // (if it renders earlier, the loader does not obstruct it)
    $timeout(() => ($rootScope.hideLoader = true), 1000);

    initGlobalExceptionHandler();
    getRootStore().chatApi.setTechseeSocketManager(socketInstance);
    getRootStore().chatApi.setSafeApply(safeApply);
    getRootStore().cobrowsingService.setSafeApply(safeApply);
    getRootStore().chatApi.init(getRootStore().termsAndConditionsController);
    getRootStore().stateHelper.init($state, $rootScope, $http);
}

function bootstrapAngularModule() {
    /* If the loader has started a redirect,
     * we don't need to run the app */
    if (window.loaderState !== LOADER_STATES.REDIRECTING) {
        getBrowserInfo().then(() => {
            angular
                .module('app', [
                    vendorModule.name,
                    componentsModule.name,
                    directivesModule.name,
                    filtersModule.name,
                    servicesModule.name,
                    constantsModule.name,
                    statesModule.name
                ])
                .run(afterRun)
                .config(config);

            // Init the app
            angular.bootstrap(document, ['app'], {
                // Make sure every DI occurrence is properly annotated
                strictDi: true
            });
        });
    }
}

await reactAppBootstrap();

// Make sure the loader is finished when the angular module bootstraps
if (window.loaderState === LOADER_STATES.FINISHED) {
    bootstrapAngularModule();
} else if (window.loaderState === LOADER_STATES.MEETING_STARTED) {
    bootstrapAngularModule();
} else if (window.loaderState === LOADER_STATES.RUNNING) {
    const appTimer = window.setInterval(() => {
        if (window.loaderState !== LOADER_STATES.RUNNING) {
            window.clearInterval(appTimer);

            bootstrapAngularModule();
        }
    }, 200);
}

function configSocketManager(socketUrl, tsBrowserUtilsService, tsEventService, tsRoomChannelTracer) {
    const logType = 'TECHSEE_ROOM_CHANNEL_TRACE';

    if (ENV.dev && TRACING.ROOM_CHANNEL) {
        tsRoomChannelTracer.setLoggingFunction(({traceMessage, roomInfo, userId, extraData}) => {
            console.log(logType + ':' + traceMessage, {
                roomId: roomInfo && roomInfo.roomId,
                roomInfo,
                userId,
                extraData
            });
        });
    } else if (TRACING.ROOM_CHANNEL) {
        tsRoomChannelTracer.setLoggingFunction(({traceMessage, roomInfo, userId, extraData}) => {
            tsEventService.sendEventLog(userId, roomInfo && roomInfo.roomId, logType, {
                traceMessage,
                roomInfo,
                extraData
            });
        });
    }

    const defaultSocketConfig = TechseeSocketManager.getDefaultConfig(socketUrl);

    defaultSocketConfig.browserUtilsService = tsBrowserUtilsService;
    defaultSocketConfig.channelTracer = tsRoomChannelTracer;
    TechseeSocketManager.setConfig(defaultSocketConfig);

    return TechseeSocketManager.instance();
}

function configureMobileAppTracing(tsEventService, $localStorage) {
    //run localStorage.setItem('ngStorage-TRACE_TO_CONSOLE', true) in DevTools console
    if ($localStorage.TRACE_TO_CONSOLE === true) {
        registerTracerOutput(traceToConsoleOutputFunction('MobileTrace'));
    }

    registerTracerOutput(tsEventService.traceToEventLogOutputFunction);

    enableTracerByName(TECHSEE_MEDIA_TRACER);
    enableTracerByName(MEETING_TRACER);
}

function initGlobalExceptionHandler() {
    window.onerror = (msg, src, line, col, error) => {
        if (typeof msg === 'string' && startsWith(msg, 'Uncaught Error: [$rootScope:infdig]')) {
            return true;
        }

        const details = {
            clientType: PlatformType.mobile_web,
            error: {msg, src, line, col, error}
        };

        let roomId = getRootStore().chatApi.roomId ?? 'none';

        return getRootStore().eventService.sendEventLog('none', roomId, STATUS_MESSAGES.UNCAUGHT_EXCEPTION, details);
    };
}
