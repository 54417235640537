import React from 'react';
import {observer} from 'mobx-react';
import {ReactResponsiveCarouselWrapper} from '../react-responsive-carousel-wrapper/reactResponsiveCarouselWrapper';
import {getRootStore} from '../../app.bootstrap';
import {Message} from './FullscreenGallery.controller';
import './ts-fullscreen-gallery.style.scss';

interface FullscreenGalleryProps {
    messages: Message[];
}

const FullscreenGalleryComponent: React.FC<FullscreenGalleryProps> = ({messages}) => {
    const controller = getRootStore().fullscreenGalleryController;

    return (
        <div className='ts-fullscreen-gallery'>
            <ReactResponsiveCarouselWrapper
                activeMessage={controller.activeMessage}
                messages={messages.filter((msg) => controller.isSupportedMsg(msg))}
            />
        </div>
    );
};

export const FullscreenGallery = observer(FullscreenGalleryComponent);
