import React, {useState} from 'react';
import {getRootStore} from '../../app.bootstrap';
import {requireImage} from '../../utils/utils';

import './ts-chat-control.style.scss';

interface ChatControlProps {
    onChatFocused?: (isFocused: boolean) => void;
    isDesktop?: boolean;
}

const ChatControl: React.FC<ChatControlProps> = ({onChatFocused, isDesktop}) => {
    const [textMessage, setTextMessage] = useState('');

    const tsBrowserUtilsService = getRootStore().browserUtilsService;
    const chatApi = getRootStore().chatApi;
    const enterTextMessage = getRootStore().localizationService.translate('CHAT_CONTROL.VIEW.ENTER_TEXT_MESSAGE');

    const sendTextMessage = (event?: React.FormEvent) => {
        event?.preventDefault();
        if (!textMessage.trim()) return;

        chatApi.sendText(textMessage, {});
        tsBrowserUtilsService.saveToLocalStorage('tos_accepted', chatApi.roomId);
        setTextMessage('');
    };

    const handleFocusChange = (isFocused: boolean) => {
        onChatFocused?.(isFocused);
    };

    const isButtonActive = (): boolean => {
        return !!textMessage && (chatApi.bothSidesConnected() || chatApi.offlineRoom);
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (!isDesktop) return;

        if (!(event.shiftKey || event.ctrlKey) && event.key === 'Enter') {
            event.preventDefault();
            sendTextMessage();
        } else if ((event.shiftKey || event.ctrlKey) && event.key === 'Enter') {
            setTextMessage((prev) => prev + '\n');
        }
    };

    return (
        <form name='sendTextForm' className='ts-chat-input-container' onSubmit={sendTextMessage}>
            {!isDesktop ? (
                <textarea
                    placeholder={enterTextMessage}
                    className='send-message-input'
                    name='text-message'
                    rows={1}
                    value={textMessage}
                    onChange={(e) => setTextMessage(e.target.value)}
                    onFocus={() => handleFocusChange(true)}
                    onBlur={() => handleFocusChange(false)}
                />
            ) : (
                <textarea
                    placeholder={enterTextMessage}
                    className='send-message-input'
                    name='text-message'
                    rows={1}
                    value={textMessage}
                    onChange={(e) => setTextMessage(e.target.value)}
                    onFocus={() => handleFocusChange(true)}
                    onBlur={() => handleFocusChange(false)}
                    onKeyDown={handleKeyDown}
                />
            )}

            <button
                className={`send-message-btn ts-chat-control-btn ${isButtonActive() ? 'btn-chat-active' : ''}`}
                type='submit'
                disabled={!isButtonActive()}>
                <img src={requireImage('icon_send.png')} className='send-message-btn-icon' alt='Send Message' />
            </button>
        </form>
    );
};

export default ChatControl;
