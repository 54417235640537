import {useEffect, RefObject} from 'react';

export const useAutoFocus = (ref: RefObject<HTMLElement>, delay: number = 0) => {
    useEffect(() => {
        const timeout = setTimeout(() => {
            ref.current?.focus();
        }, delay);

        return () => clearTimeout(timeout);
    }, [ref, delay]);
};
