import '@techsee/techsee-ui-common/lib/_shared/icons.css';
import React from 'react';
import {configure} from 'mobx';
import {Provider} from 'mobx-react';
// @ts-ignore
import MeetingView from './meeting.view.html';
import {MeetingStores, IMeetingStores} from './meeting.stores';
import {FlowType} from '@techsee/techsee-common/lib/constants/room.constants';
import isEmpty from 'lodash/isEmpty';
import {MeetingContainer} from './components/layout/MeetingContainer';
import {ITsEventService} from '../../services/ts-event-service/ts-event-service';
import {STATUS_MESSAGES} from '../../../states/meeting/meeting.settings';
import {MediaServiceType} from '@techsee/techsee-media-service/lib/MediaConstants';
import {LOADER_STATES} from '../../../../loader/loader.settings';
import {getRootStore} from '../../app.bootstrap';

declare const angular: any;

declare global {
    interface Window {
        loaderState: any;
    }
}

//Configures MobX to allow changing state from actions only.
configure({
    enforceActions: 'observed'
});

export type MeetingRootComponentProps = {
    stores: IMeetingStores;
};

export class MeetingRootComponent extends React.Component<MeetingRootComponentProps> {
    render() {
        const {stores} = this.props;

        if (!stores) {
            return null;
        }

        const providerStores: any = {};

        Object.getOwnPropertyNames(stores).forEach((storeName) => {
            providerStores[storeName] = stores[storeName];
        });

        return (
            <Provider {...providerStores}>
                <MeetingContainer />
            </Provider>
        );
    }
}

const reactDirectiveWrapper = ['reactDirective', (reactDirective: any) => reactDirective(MeetingRootComponent)];

export default angular
    .module('states.newMeeting', [])
    .directive('meetingRootComponent', reactDirectiveWrapper)
    .config(['$stateProvider', config]);

function config($stateProvider: any) {
    $stateProvider.state('newMeeting', {
        url: BASE_PATH + 'newMeeting?room&roomCode&g&csi&t&media-service',
        template: MeetingView,
        controller: MeetingStores,
        controllerAs: 'newMeeting',
        resolve: {
            currentUser: [
                'db',
                (db: any) => {
                    'ngInject';

                    return db.User.find('current')
                        .then((user: any) => {
                            getRootStore().roomChannelTracer.setCurrentUserId(user._id);

                            return user;
                        })
                        .catch(() => ({}));
                }
            ],

            sessionData: [
                'db',
                'currentUser',
                (db: any, currentUser: any) => {
                    'ngInject';

                    const guid = getRootStore().urlUtils.getParamValue('g');
                    const tsEventService: ITsEventService = getRootStore().eventService;

                    if (!guid) {
                        return {
                            params: {}
                        };
                    }

                    return new Promise((resolve) => {
                        db.Shorturl.find(guid)
                            .then((entry: any) => {
                                if (entry) {
                                    entry.guid = guid;
                                }

                                resolve(entry);
                            })
                            .catch((err: any) => {
                                tsEventService.sendEventLog(
                                    currentUser && currentUser._id,
                                    'none',
                                    STATUS_MESSAGES.CUSTOMER_LOADING_SHORT_URL_ERROR,
                                    {
                                        error: err,
                                        guid,
                                        errorLocation: 'Failed to find shortUrl | new meeting module'
                                    }
                                );

                                if (err.status === 404) {
                                    return getRootStore().stateHelper.safeGo('endNew', {
                                        expiredRoom: true
                                    });
                                }

                                return resolve({
                                    params: {},
                                    err
                                });
                            });
                    });
                }
            ],

            roomInfo: [
                'sessionData',
                'currentUser',
                (sessionData: any, currentUser: any) => {
                    'ngInject';

                    if (getRootStore().browserUtilsService.getFromSessionStorage('shouldEndSession')) {
                        return getRootStore().stateHelper.safeGo('endNew', {});
                    }

                    const roomInfo = {
                        _id: getRootStore().urlUtils.getParamValue('room') || sessionData.params.room,
                        roomCode: getRootStore().urlUtils.getParamValue('roomCode') || sessionData.params.roomCode,
                        intent: getRootStore().urlUtils.getParamValue('intent') || sessionData.params.intent,
                        mediaServiceType: (getRootStore().urlUtils.getParamValue('media-service') ||
                            sessionData.params['media-service']) as MediaServiceType,
                        usingApplication: getRootStore().environmentDetect.isApplicationWebView(
                            getRootStore().urlUtils.getParamValue('ref') as string
                        ),
                        flowType: getRootStore().urlUtils.getParamValue('flow-type') || sessionData.params['flow-type'],
                        enforceAuth: getRootStore().urlUtils.getParamValue('ea') || sessionData.params.ea,
                        referrer: getRootStore().urlUtils.getParamValue('ref'),
                        audio: getRootStore().urlUtils.getParamValue('audio') || sessionData.params.audio
                    };

                    if (!roomInfo._id && !roomInfo.roomCode) {
                        return getRootStore().stateHelper.safeGo('start.main', {});
                    }
                    if (roomInfo.enforceAuth !== 'no' && roomInfo.flowType === FlowType.fs && isEmpty(currentUser)) {
                        const guid = getRootStore().urlUtils.getParamValue('g');

                        return getRootStore().stateHelper.safeGo('fs', {g: guid});
                    }

                    return roomInfo;
                }
            ],
            verboseLogs: [
                'sessionData',
                'roomInfo',
                'currentUser',
                (sessionData: any, roomInfo: any, currentUser: any) => {
                    'ngInject';

                    const tsEventService: ITsEventService = getRootStore().eventService;

                    if (sessionData && sessionData.params['verbose-logging']) {
                        try {
                            tsEventService.setRoomDetails(roomInfo._id, roomInfo.roomCode);
                        } catch (err) {
                            tsEventService.sendEventLog(
                                currentUser && currentUser._id,
                                roomInfo?._id,
                                STATUS_MESSAGES.CUSTOMER_LOADING_VERBOS_LOGS_ERROR,
                                {error: err, errorLocation: 'Failed to initiate VerboseLogs | new meeting module'}
                            );
                        }
                    }
                }
            ],
            setMeetingStarted: () => {
                window.loaderState = LOADER_STATES.MEETING_STARTED;
            }
        }
    });
}
