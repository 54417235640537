import React from 'react';
import {RoundedButton} from '../../../rounded-button/RoundedButton';

import './ts-cobrowsing-control.style.scss';

export interface CoBrowsingControlProps {
    onRelocateUrlClick: () => void;
    isInControl: boolean;
    isPaused: boolean;
    isInChatPage: boolean;
}

export const CoBrowsingControl: React.FC<CoBrowsingControlProps> = ({
    onRelocateUrlClick,
    isInControl,
    isPaused,
    isInChatPage
}) => {
    return (
        <div className={'ts-cobrowsing-input-container'}>
            <RoundedButton
                color={'blue'}
                size={'normal'}
                icon={'cb-url'}
                onClick={onRelocateUrlClick}
                disabled={!isInControl || isPaused || isInChatPage}
                customCss={isInControl && !isPaused ? 'btn-chat-active' : ''}
            />
        </div>
    );
};

// TODO all of those are was marked and should be implemented when needed

// <rounded-button
//     ng-if="vm.displayExpandButton()"
//     disabled="!vm.displayChatGalleryBtn()"
//     custom-css="'cobrowsing-control'" size="'normal'" color="'blue'"
//     ng-click="vm.chatHelper.expandButtonClick(!vm.displayChatGalleryBtn())"
//     icon="'cb-chat'"></rounded-button>
//

// <rounded-button color="'blue'" size="'normal'" icon="'cb-draw'"
//                 ng-click="vm.draw()"
//                 disabled="!vm.isInControl() || vm.isPaused() || vm.isInChatPage()"
//                 uib-popover="{{'CO_BROWSING.VIEW.TOOLTIP.EXIT_DRAW' | translate}}"
//                 popover-placement="top"
//                 popover-is-open="{{vm.drawMode}}"
//                 ng-class="{'btn-light-blue' : vm.drawMode}"></rounded-button>
// <rounded-button color="'blue'" size="'normal'" icon="vm.getControlIcon()" is-wide-icon="!vm.isInControl()"
//                 ng-click="vm.switchControl()"
//                 disabled="!vm.isInControl() || vm.isPaused() || vm.isInChatPage()"
//                 ng-class="{'btn-chat-active' : vm.isInControl() && !vm.isPaused()}"></rounded-button>
// <ts-file-loader file-model="vm.photoSrc"
//                 load-finished="vm.loadFinished(objUrl)"
//                 file-fixer="vm.imageFixer"
//                 file-type="image"
//
//                 className="choose-from-gallery co-browsing">
//     <ts-rounded-button color="blue" size="normal" icon="add"></ts-rounded-button>
// </ts-file-loader>

//TODO this is the controler but most of the no working and not needed right now. when needed will be implemented and need to take the logic from the controller and do refactor

// class tsCobrowsingControlController {
//     constructor($scope, $rootScope, tsChatHelper, changeUrlModal) {
//         'ngInject';
//
//         this.rootScope = $rootScope;
//
//         // Outgoing image url
//         this.photoSrc = null;
//         this.chatHelper = tsChatHelper;
//         this.messageHistory = tsChatHelper.messageHistory;
//         this.chatHelper.setMessageHistory(this.messageHistory);
//         this.imageFixer = getRootStore().imageFixer;
//         this.chatApi = getRootStore().chatApi;
//         this.drawMode = false;
//         this.tsCobrowsing = getRootStore().cobrowsingService;
//         this.changeUrlModal = changeUrlModal;
//
//         this.tsCobrowsing.on(this.tsCobrowsing.EVENTS.INIT_ERROR, (err) => console.error(err));
//         this.tsCobrowsing.on(this.tsCobrowsing.EVENTS.ERROR, (err) => console.error(err));
//         this.tsCobrowsing.on(this.tsCobrowsing.EVENTS.CONTROL_SWITCH, () => $scope.$applyAsync());
//     }
//
//     get requireImage() {
//         return this.rootScope.requireImage;
//     }
//
//     showMediaButtons() {
//         return get(this.chatApi, 'accountSettings.allowUploadImagesFromVideoAndPhoto');
//     }
//
//     get currentUrl() {
//         return get(this.chatApi, 'dashboard.coBrowsingSettings.url');
//     }
//
//     relocateUrl() {
//         this._cancelDraw();
//
//         const modal = this.changeUrlModal.show(HTTPS);
//
//         if (!modal) {
//             return;
//         }
//
//         return modal.then((newUrl) => newUrl && this._relocateUrl(newUrl)).catch(() => false);
//     }
//
//     _relocateUrl(url) {
//         this._cancelDraw();
//         this.onRelocate({url: url});
//     }
//
//     draw() {
//         this.drawMode = !this.drawMode;
//
//         this.onDrawRequest({enabled: this.drawMode});
//     }
//
//     switchControl() {
//         this._cancelDraw();
//         this.onSwitchControl();
//     }
//
//     _cancelDraw() {
//         if (this.drawMode) {
//             this.onDrawRequest({enabled: false});
//             this.drawMode = false;
//         }
//     }
//
//     loadFinished(objUrl) {
//         this.sendPhoto(objUrl);
//     }
//
//     sendPhoto(photoSrc, retryIndex) {
//         const sendPhotoOptions = {
//             photoSrc: photoSrc,
//             origin: MeetingMode.coBrowsing,
//             withConfirm: true,
//             withLoader: true,
//             retryIndex: retryIndex
//         };
//
//         this.chatHelper.sendMedia(sendPhotoOptions).catch(() => null);
//     }
//
//     displayChatGalleryBtn() {
//         return (
//             this.chatApi.connected && (this.messageHistory.messages.length > 0 || this.chatHelper.chatControlFocused)
//         );
//     }
//
//     isInControl() {
//         return this.tsCobrowsing.isInControl;
//     }
//
//     isPaused() {
//         return this.tsCobrowsing.isPaused;
//     }
//
//     getControlIcon() {
//         return this.isInControl() ? 'cb-hand' : 'cb-eye';
//     }
// }
//
// export function tsCobrowsingControlDirective() {
//     'ngInject';
//
//     return {
//         template: tsCobrowsingControlView,
//         replace: true,
//         restrict: 'E',
//         scope: {},
//         bindToController: {
//             onRelocate: '&',
//             onSwitchControl: '&',
//             onDrawRequest: '&',
//             isInChatPage: '&',
//             messageHistory: '=',
//             mode: '='
//         },
//         controller: tsCobrowsingControlController,
//         controllerAs: 'vm'
//     };
// }
