'use strict';

import {speedtestUrl} from '@techsee/techsee-common/lib/constants/account.constants';
import {LOG_EVENTS} from '@techsee/techsee-common/lib/constants/event-logs.constants';
import {CAMERA_ACCESS, ROOM_MODES, STATUS_MESSAGES, SWITCH_CAMERA_TIMEOUT, VIEW_PAGES} from '../meeting.settings.js';
import {
    CameraTypes,
    KnownMediaStream,
    LocalVideoSourceType,
    MediaServiceType
} from '@techsee/techsee-media-service/lib/MediaConstants';
import {MeetingVideoStreamControllerBase} from '../meeting.video-stream.controller.base';
import get from 'lodash/get';
import {MeetingState} from '@techsee/techsee-common/lib/constants/meeting.states.definition';
import * as socketEvents from '@techsee/techsee-common/lib/socket/client';
import {getRootStore} from '../../../_react_/app.bootstrap';

export class VideoChatController extends MeetingVideoStreamControllerBase {
    constructor(
        $localStorage,
        $sessionStorage,
        $scope,
        $rootScope,
        $stateParams,
        $timeout,
        $uibModal,
        $window,
        $sce,
        db,
        roomInfo,
        currentUser,
        mobileAppMediaService,
        audioService
    ) {
        'ngInject';

        super(
            MeetingState.VideoChat,
            $rootScope,
            $scope,
            $sessionStorage,
            $timeout,
            $window,
            $sce,
            getRootStore().chatApi,
            getRootStore().termsAndConditionsController,
            getRootStore().eventService,
            getRootStore().preCameraPermissionService,
            mobileAppMediaService,
            db,
            audioService
        );

        this.mobileAppMediaService = mobileAppMediaService;
        this.onHistoryMessageReceived = this.onHistoryMessageReceived.bind(this);

        this.$scope = $scope;
        this.$rootScope = $rootScope;
        this.$timeout = $timeout;
        this.$window = $window;
        this.$sce = $sce;
        this.$uibModal = $uibModal;
        this.stateHelper = getRootStore().stateHelper;
        this.EndMeetingConfirmationModal = getRootStore().endMeetingConfirmationController;
        this.tsTermsAndConditions = getRootStore().termsAndConditionsController;
        this.chatApi = getRootStore().chatApi;
        this.visibilityChange = getRootStore().visibilityChange;
        this.$localStorage = $localStorage;
        this.$sessionStorage = $sessionStorage;
        this.endParams = $stateParams.csi ? {csi: $stateParams.csi} : {};
        this.roomId = roomInfo.roomId;
        this.isWebRTCEnabledAppleDevice = getRootStore().environmentDetect.isWebRTCEnabledAppleDevice();
        this.isIOS = getRootStore().environmentDetect.isIOS();
        this.ROOM_MODES = ROOM_MODES;
        this.mediaServiceType = roomInfo.mediaServiceType;
        this.isTurnServer = this.mediaServiceType === MediaServiceType.TURNSERVER;
        this.chatHelper = getRootStore().chatHelper;
        this.flashlightHelper = getRootStore().flashlightHelper;
        this.flashlightHelper.initMobileAppMediaService(mobileAppMediaService);
        this.imageFixer = getRootStore().imageFixer;
        this.preCameraPermissionService = getRootStore().preCameraPermissionService;
        this.currentUser = currentUser;
        this.VIEW_PAGES = VIEW_PAGES;
        this.runSpeedTestOnClient = this.$sessionStorage.runSpeedTestOnClient;
        this.isFirefox = getRootStore().environmentDetect.isFF();
        this.visibilityChange.enableVisibilityEvents();
        this.theme = getRootStore().theme;
        this.brandingService = getRootStore().brandingService;
        this.brandingData = this.brandingService.getBrandingData();
        this.endMeetingButtonAria = getRootStore().localizationService.translate('END.VIEW.END_MEETING_BUTTON_ARIA');
        this.endMeetingModalService = getRootStore().endMeetingConfirmationController;
        this.onClickEndMeetingButton = this.endMeetingModalService.pop;
        this.fullscreenGalleryController = getRootStore().fullscreenGalleryController;
        this.retryImageUpload = this.retryImageUpload.bind(this);
        this.loadFinished = this.loadFinished.bind(this);

        this.messageHistory = getRootStore().chatHelper.messageHistory;

        this.modalInstance = null;

        this.photoSrc = null;

        this.speedTestButtonAria = getRootStore().localizationService.translate(
            'VIDEO_CHAT.VIEW.SPEED_TEST_BUTTON_ARIA'
        );

        this.chooseFromGalleryButtonAria = getRootStore().localizationService.translate(
            'VIDEO_CHAT.VIEW.CHOOSE_FROM_GALLERY_BUTTON_ARIA'
        );

        this.expandButtonAria = getRootStore().localizationService.translate('VIDEO_CHAT.VIEW.EXPAND_BUTTON_ARIA');

        this.videoThumbnailAria = getRootStore().localizationService.translate('VIDEO_CHAT.VIEW.VIDEO_THUMBNAIL_ARIA');

        this.switchCameraButtonAria = getRootStore().localizationService.translate(
            'VIDEO_CHAT.VIEW.SWITCH_CAMERA_BUTTON_ARIA'
        );

        this.pauseVideoButtonAria = getRootStore().localizationService.translate('VIDEO_CHAT.VIEW.PAUSE');

        this.resumeVideoButtonAria = getRootStore().localizationService.translate(
            'MESSAGE.VIEW.DESKTOP_SHARING.RESUME'
        );

        this.setVoipMicStatusButtonAria();

        this.$scope.$on('$viewContentLoaded', () => {
            // make the code execute on the next cycle
            this.$timeout(() => {
                this.sendEventLog(STATUS_MESSAGES.CLIENT_VIDEO_VIEW_LOADED, {
                    roomId: this.tsChatApi.roomId
                });
            }, 0);
        });

        this.notifyConstructionIsReady();
    }

    get currentRole() {
        return this.currentUser && this.currentUser.role;
    }

    get loopbackStreamType() {
        return KnownMediaStream.USER_VIDEO_STREAM;
    }

    setStreamSource() {
        this.mobileAppMediaService.setCameraStreamSource(
            this.chatApi.dashboardVideoResolution,
            get(this.tsChatApi, 'accountSettings.customerDefaultCamera')
        );
    }

    meetingModeHandshakeSuccess() {
        this.chatHelper.setMode();
        this.chatApi.setStatus(socketEvents.CLIENT_OUT_SET_STATUS.VIDEO_HANDSHAKE_SUCCESS, true);

        this.chatApi.cameraApprovalDialogStateChange(false);
        this.chatApi.sendLog(STATUS_MESSAGES.MEDIA_PERMISSION_ALLOW);
        this.chatApi.requestAction(
            socketEvents.CLIENT_OUT_REQUEST_ACTION.IS_TORCH_AVAILABLE,
            this.isFlashlightSupported()
        );

        const mobileSpeedtestDefaultProvider = get(this.chatApi, 'accountSettings.mobileSpeedtestDefaultProvider');

        this.speedtestUrl = mobileSpeedtestDefaultProvider
            ? speedtestUrl.ookla
            : get(this.chatApi, 'accountSettings.mobileSpeedtestUrl');
        this.SPEEDTEST_URL = this.$sce.trustAsResourceUrl(this.speedtestUrl);

        if (this.chatApi.dashboard.opentok && this.chatApi.client.videoSupport && this.chatApi.connected) {
            this.$localStorage.wasInVideoMode = true;
        }

        this.mobileAppMediaService.checkMediaDevicesSupport();

        this.sendEventLog(STATUS_MESSAGES.MEDIA_PERMISSION_ALLOW, {
            roomId: this.chatApi.roomId,
            userId: get(this.currentUser, '_id'),
            meta: {
                description: 'Customer approved camera access',
                origin: ROOM_MODES.video,
                cameraType: this.mobileAppMediaService.currentCameraType
            }
        });

        if (get(this.chatApi, 'accountSettings.enableFlashlight')) {
            this.sendEventLog(LOG_EVENTS.flashlightCompatibility, {
                roomId: this.chatApi.roomId,
                meta: {isTorchAvailable: this.isFlashlightSupported()}
            });
        }

        this.setReportedField(this.roomId, {
            data: {
                event: {
                    key: 'cameraAccess',
                    value: CAMERA_ACCESS.CAMERA_APPROVED
                }
            }
        });

        this.setReportedField(this.roomId, {
            data: {
                event: {
                    key: 'streamingIntent',
                    value: ROOM_MODES.video,
                    type: 'push'
                }
            }
        });

        this.setReportedField(this.roomId, {
            data: {
                event: {
                    key: 'usedCameras',
                    value:
                        this.mobileAppMediaService.currentVideoSourceType === LocalVideoSourceType.CAMERA_FRONT
                            ? CameraTypes.FRONT
                            : CameraTypes.BACK,
                    type: 'push'
                }
            }
        });
    }

    //#region Meeting Pipeline

    syncVideoState() {
        this.chatApi.requestAction(socketEvents.CLIENT_OUT_REQUEST_ACTION.IS_TORCH_ON, this.flashlightHelper.isTorchOn);
        this.chatApi.requestAction(
            socketEvents.CLIENT_OUT_REQUEST_ACTION.IS_TORCH_AVAILABLE,
            this.isFlashlightSupported()
        );

        if (!this.isWebRTCEnabledAppleDevice && !get(this.tsChatApi, 'accountSettings.clientReloadOnVisibility')) {
            if (!this.visibilityChange.isDisabled()) {
                this.visibilityChange.disableVisibilityEvents();
            }
        }
    }

    switchCamera(e) {
        e.preventDefault();

        if (this.disableSwitchingAccess) {
            return;
        }

        this.disableSwitchingAccess = true;
        setTimeout(() => {
            this.disableSwitchingAccess = false;
        }, SWITCH_CAMERA_TIMEOUT);

        this.mobileAppMediaService.switchSourceCamera();
    }

    initVideoModeHandlers() {
        this.enableCustomerSwitchingCamera = get(this.tsChatApi, 'accountSettings.enableCustomerSwitchingCamera');

        this.endMeetingModalService.init(true);
        this.isNewEndMeetingButton = this.endMeetingModalService.isNewStyle;

        const visibleListener = () => {
            if (!this.visibilityChange.isDisabled()) {
                this.$window.location.reload();
            }
        };

        this.visibilityChange.on('visible', visibleListener);

        this.$scope.$on('$destroy', () => {
            this.chatHelper.closePopups();

            if (this.modalInstance) {
                this.modalInstance.close();
            }

            this.visibilityChange.off('visible', visibleListener);
        });

        window.addEventListener('focus', () => {
            if (this.flashlightHelper.isTorchOn) {
                this.flashlightHelper.setTorch(false);

                this.sendEventLog(LOG_EVENTS.flashlightStatus, {
                    roomId: this.roomId,
                    meta: {status: false, reason: 'Window focus lost'}
                });

                this.$scope.$applyAsync();
            }
        });

        this.mobileAppMediaService.onSwitchCameraSuccess((eventArgs) => {
            this.setReportedField(this.roomId, {
                data: {
                    event: {
                        key: 'usedCameras',
                        value:
                            this.mobileAppMediaService.currentVideoSourceType === LocalVideoSourceType.CAMERA_FRONT
                                ? CameraTypes.FRONT
                                : CameraTypes.BACK,
                        type: 'push'
                    }
                }
            });

            const previousCameraType =
                eventArgs.videoSourceType === CameraTypes.BACK ? CameraTypes.FRONT : CameraTypes.BACK;

            if (this.isTurnServer && eventArgs.audioCreated) {
                this.audioService.initAudioMute();
            }

            this.sendEventLog(STATUS_MESSAGES.SWITCH_CAMERA_SUCCESS, {
                roomId: this.chatApi.roomId,
                userId: get(this.currentUser, '_id'),
                meta: {
                    description: 'Success to switch camera ',
                    fromCamera: previousCameraType,
                    toCamera: eventArgs.videoSourceType
                }
            });

            if (this.isFlashlightSupported && this.mobileAppMediaService.isFrontCamera) {
                this.flashlightHelper.setTorch(false);
            }
        });

        this.mobileAppMediaService.onSwitchCameraFailed((eventArgs) => {
            const previousCameraType =
                eventArgs.videoSourceType === CameraTypes.BACK ? CameraTypes.FRONT : CameraTypes.BACK;

            this.sendEventLog(STATUS_MESSAGES.SWITCH_CAMERA_FAILED, {
                roomId: this.chatApi.roomId,
                userId: get(this.currentUser, '_id'),
                meta: {description: 'Failed to switch camera ', to: previousCameraType}
            });
        });
    }

    get showNewTitles() {
        const enableStretchedVideoMode = get(this.chatApi, 'accountSettings.enableStretchedVideoMode');
        const enableRoundedMinimizedVideo = get(this.chatApi, 'accountSettings.enableRoundedMinimizedVideo');
        const displayPauseIndicationOnMobile = get(this.chatApi, 'accountSettings.displayPauseIndicationOnMobile');

        return displayPauseIndicationOnMobile && enableStretchedVideoMode && enableRoundedMinimizedVideo;
    }

    get isBusyBackground() {
        return get(this.chatApi, 'client.videoHandshakeSuccess') !== true;
    }

    displayExpandButton() {
        return (
            (get(this.chatApi, 'accountSettings.enableNewVideoOnMobile') &&
                this.chatApi.connected &&
                this.messageHistory &&
                this.messageHistory.messages.length > 0) ||
            this.chatHelper.chatControlFocused
        );
    }

    displaySpeedtestButton() {
        return (
            get(this.chatApi, 'accountSettings.enableNewVideoOnMobile') &&
            get(this.chatApi, 'accountSettings.enableMobileSpeedtest') &&
            this.runSpeedTestOnClient &&
            this.chatHelper.isInMainPage
        );
    }

    isFlashlightSupported() {
        return (
            get(this.chatApi, 'accountSettings.enableFlashlight') &&
            !this.isIOS &&
            !this.isFirefox &&
            this.isTurnServer &&
            !this.isThumbnailMode() &&
            !this.mobileAppMediaService.isFrontCamera
        );
    }

    get isSendingMedia() {
        return this.chatHelper.isSendingMedia;
    }

    displayChatControl() {
        return get(this.chatApi, 'accountSettings.enableNewVideoOnMobile') && this.chatHelper.showChatControl();
    }

    // File Loader handlers
    loadFinished(objUrl) {
        this.sendPhoto(objUrl);
    }

    showMediaButtons() {
        return get(this.chatApi, 'accountSettings.allowUploadImagesFromVideoAndPhoto');
    }

    showImageUploadSuccessNotification() {
        return this.chatHelper.showImageUploadSuccess;
    }

    showImageUploadFailureNotification() {
        return this.chatHelper.showImageUploadFailure;
    }

    sendPhoto(photoSrc, retryIndex) {
        const sendPhotoOptions = {
            photoSrc: photoSrc,
            origin: ROOM_MODES.video,
            withConfirm: false,
            withLoader: true,
            retryIndex: retryIndex
        };

        this.chatHelper.sendMedia(sendPhotoOptions).catch(() => null);
    }

    get isVideoControlsVisible() {
        return this.isHandshakeSuccess && !this.isThumbnailMode();
    }

    toggleLightMobile() {
        this.flashlightHelper.toggleLightMobile().then(() => this.$scope.$applyAsync());
    }

    togglePauseMobile() {
        super.togglePauseMobile();

        if (this.isPausedByMobile && get(this.tsChatApi, 'accountSettings.enableFlashlight') && this.isTurnServer) {
            this.mobileAppMediaService
                .turnFlashlight(false, {reason: {isPausedByMobile: this.isPausedByMobile}})
                .then((success) => {
                    this.tsEventService.sendEventLog(
                        'none',
                        this.tsChatApi.roomId || 'none',
                        success ? LOG_EVENTS.flashlightStatus : LOG_EVENTS.flashlightError.type,
                        {
                            status: false
                        }
                    );
                })
                .then(() => this.$scope.$applyAsync());
        }
    }

    toggleUserMicrophone() {
        this.setVoipMicStatusButtonAria();
        this.audioService.toggleUserMicrophone();
    }

    setVoipMicStatusButtonAria() {
        const voipMicStatus = get(this.chatApi, 'client.audioMuted')
            ? 'AUDIO_NOTICE.VIEW.MUTE_BUTTON_ARIA'
            : 'AUDIO_NOTICE.VIEW.UNMUTE_BUTTON_ARIA';

        this.voipMicStatusButtonAria = getRootStore().localizationService.translate(voipMicStatus);
    }

    retryImageUpload(index) {
        this.sendPhoto(this.messageHistory.messages[index].data, index);
    }

    isThumbnailMode() {
        return this.chatHelper.videoSettings.videoSize === this.chatHelper.VIDEO_SIZES.THUMBNAIL;
    }

    get showActionGuidance() {
        return this.isPausedByMobile && this.showNewTitles && this.chatHelper.isInMainPage;
    }
}
