'use strict';

import {tsIframeOverlayDirective} from './ts-iframe-overlay/ts-iframe-overlay.directive';
import {tsChatControlDirective} from './ts-chat-control/ts-chat-control.directive';
import {tsPopupDirective} from './ts-popup/ts-popup.directive';
import {tsConnectToCenterDirective} from './ts-connect-to-center/ts-connect-to-center.directive';
import {tsChatConnectDirective} from './ts-chat-connect/ts-chat-connect.directive';
import {ConfirmationModal} from '@techsee/techsee-ui-common/lib/modals/confirmation-modal';
import {BorderRoundedIcon} from '@techsee/techsee-ui-common/lib/icons/large-rounded-icon';
import {ErrorMessageCloseSession} from './error-message-close-session/component';
import {MessageAlert} from '@techsee/techsee-ui-common/lib/message-alert';
import {EndMeetingComponent} from '../_react_/states/meeting/components/modal/end-meeting/EndMeetingComponent';
import {PermissionModal} from '../_react_/states/components/generic/permission-modal';
import Translate from '../_react_/component/Translate';
import {TermsAndConditionComponent} from '../_react_/component/terms-and-condition/TermsAndCondition';
import NonBlockingSpinner from '../_react_/component/non-blocking-spinner/nonBlockingSpinner';
import {EndMeetingConfirmation} from '../_react_/component/end-meeting-confirmation/EndMeetingConfirmation';
import {BusyBackground} from '../_react_/component/ts-busy-background/BusyBackground';
import {RoundedButton} from '../_react_/component/rounded-button/RoundedButton';
import {EndMeetingButton} from '../_react_/component/end-meeting-button/EndMeetingButton';
import {ReactResponsiveCarouselWrapper} from '../_react_/component/react-responsive-carousel-wrapper/reactResponsiveCarouselWrapper';
import {FullscreenGallery} from '../_react_/component/ts-fullscreen-gallery/FullscreenGallery';
import {CoBrowsingTitle} from '../_react_/component/co-browsing/title/CoBrowsingTitle';
import {CoBrowsingControl} from '../_react_/component/co-browsing/title/control/CoBrowsingControl';
import {Chat} from '../_react_/component/ts-chat/Chat';
import {UploadConfirmation} from '../_react_/component/upload-confirmation/uploadConfirmation';
import {StateTitle} from '../_react_/component/state-title/StateTitle';
import {TsActionGuidance} from '../_react_/component/ts-action-guidance/TsActionGuidance';
import ChatControl from '../_react_/component/ts-chat-control/ts-chat-control';
import FileLoader from '../_react_/component/file-loader/file-loader';
import ZoomableImage from '../_react_/component/zoomable-image/ZoomableImage';
import {MessageModal} from '../_react_/component/message-modal/Message';
import Unsupported from '../_react_/component/unsupported/Unsupported';

export default angular
    .module('app.components', [])
    .directive('tsIframeOverlay', tsIframeOverlayDirective)
    .directive('tsChatControl', tsChatControlDirective)
    .directive('tsPopup', tsPopupDirective)
    .directive('tsConnectToCenter', tsConnectToCenterDirective)
    .directive('tsChatConnect', tsChatConnectDirective)
    .directive('tsConfirmationModal', (reactDirective) => reactDirective(ConfirmationModal))
    .directive('borderRoundedIcon', (reactDirective) => reactDirective(BorderRoundedIcon))
    .directive('tsErrorMessageCloseSession', (reactDirective) => reactDirective(ErrorMessageCloseSession))
    .directive('tsMessageAlert', (reactDirective) => reactDirective(MessageAlert))
    .directive('tsEndMeeting', (reactDirective) => reactDirective(EndMeetingComponent))
    .directive('tsTermsAndCondition', (reactDirective) => reactDirective(TermsAndConditionComponent))
    .directive('tsTranslate', (reactDirective) => {
        return reactDirective(Translate, ['text', 'values']);
    })
    .directive('stateTitle', (reactDirective) => {
        return reactDirective(StateTitle, ['title']);
    })
    .directive('busyBackground', (reactDirective) => {
        return reactDirective(BusyBackground, ['theme', 'logoWatermarkImage']);
    })
    .directive('roundedButton', (reactDirective) => {
        return reactDirective(RoundedButton, ['icon', 'color', 'size', 'isWideIcon', 'disabled', 'customCss']);
    })
    .directive('endMeetingButton', (reactDirective) => {
        return reactDirective(EndMeetingButton, ['isNewStyle', 'endMeetingButtonAriaLabel', 'onClick']);
    })
    .directive('coBrowsingTitle', (reactDirective) => {
        return reactDirective(CoBrowsingTitle, [
            'isPaused',
            'isInControl',
            'onEndMeetingClick',
            'onPauseClick',
            'onResumeClick',
            'title'
        ]);
    })
    .directive('zoomableImage', (reactDirective) => {
        return reactDirective(ZoomableImage, [
            'isNew',
            'src',
            'type',
            'altText',
            'isZoomed',
            'imageLoadStarted',
            'imageLoaded',
            'imageLoadFailed'
        ]);
    })
    .directive('messageModal', (reactDirective) => {
        return reactDirective(MessageModal, ['messages', 'initialPhotoIndex', 'onClose']);
    })
    .directive('chat', (reactDirective) => {
        return reactDirective(Chat, [
            'theme',
            'logoWatermarkImage',
            'messages',
            'onImageClicked',
            'onRetryImageUploadClicked',
            'showMediaIndexes'
        ]);
    })
    .directive('coBrowsingControl', (reactDirective) => {
        return reactDirective(CoBrowsingControl, ['isPaused', 'isInControl', 'onRelocateUrlClick', 'isInChatPage']);
    })
    .directive('tsPermissionModal', (reactDirective) => reactDirective(PermissionModal))
    .directive('nonBlockingSpinner', (reactDirective) => {
        return reactDirective(NonBlockingSpinner, ['size']);
    })
    .directive('endMeetingConfirmation', (reactDirective) => reactDirective(EndMeetingConfirmation))
    .directive('reactResponsiveCarouselWrapper', (reactDirective) => reactDirective(ReactResponsiveCarouselWrapper))
    .directive('fullscreenGallery', (reactDirective) => reactDirective(FullscreenGallery))
    .directive('uploadConfirmation', (reactDirective) => reactDirective(UploadConfirmation))
    .directive('tsActionGuidance', (reactDirective) => reactDirective(TsActionGuidance))
    .directive('chatControl', (reactDirective) => {
        return reactDirective(ChatControl, ['isDesktop', 'onChatFocused']);
    })
    .directive('fileLoader', (reactDirective) => {
        return reactDirective(FileLoader, [
            'fileModel',
            'loadOpen',
            'loadStart',
            'loadFinished',
            'loadAborted',
            'fileFixer',
            'fileType',
            'className',
            'isPhotoChat',
            'icon'
        ]);
    })
    .directive('unsupported', (reactDirective) => {
        return reactDirective(Unsupported, ['isUnsupportedBrowser']);
    });
