'use strict';

import {getMeetingTracer} from '../../../states/meeting/meeting.tracer';
import {action, computed, observable} from 'mobx';
const trace = getMeetingTracer('TsChatHelperService');

export interface IUploadConfirmationController {
    ok(): boolean;
    close(): boolean;
    isModalOpen: boolean;
    url: string;
}

export class UploadConfirmationController implements IUploadConfirmationController {
    @observable private _isModalOpen: boolean = false;
    @observable private _url: string = '';
    private _resolvePromise?: (value: boolean) => void;

    constructor() {
        this.close = this.close.bind(this);
        this.ok = this.ok.bind(this);
    }

    @computed
    get isModalOpen(): boolean {
        return this._isModalOpen;
    }

    @computed
    get url(): string {
        return this._url;
    }

    @action
    show(url: string): Promise<boolean> {
        this._isModalOpen = true;
        this._url = url;

        return new Promise((resolve) => {
            this._resolvePromise = resolve;
        });
    }

    @action
    ok() {
        this._isModalOpen = false;
        if (this._resolvePromise) {
            this._resolvePromise(true);
        }

        return true;
    }

    @action
    close() {
        this._isModalOpen = false;
        if (this._resolvePromise) {
            this._resolvePromise(false);
        }

        return false;
    }
}
