import React, {useRef, useState} from 'react';
import {getRootStore} from '../../app.bootstrap';
import {RoundedButton} from '../rounded-button/RoundedButton';
import './file-loader.scss';

interface FileLoaderProps {
    fileType: string;
    captureMode?: string;
    loadOpen?: () => void;
    loadStart?: () => void;
    loadAborted?: () => void;
    loadFinished?: (objUrl: string) => void;
    className?: string;
    icon?: string;
    isPhotoChat?: boolean;
}

const FileLoader: React.FC<FileLoaderProps> = ({
    fileType,
    captureMode,
    loadOpen,
    loadStart,
    loadAborted,
    loadFinished,
    className = '',
    icon = 'icon icon-add',
    isPhotoChat = false
}) => {
    const chatApi = getRootStore().chatApi;
    const inputRef = useRef<HTMLInputElement>(null);
    const [isProcessing, setIsProcessing] = useState(false);

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (isProcessing) return;
        if (e.target.files && e.target.files[0]) {
            setIsProcessing(true);
            loadStart?.();
            const selectedFile = e.target.files[0];

            if (getRootStore().imageFixer) {
                getRootStore()
                    .imageFixer.fix(selectedFile)
                    .then((objUrl) => {
                        loadFinished?.(objUrl);
                        setIsProcessing(false);
                    })
                    .catch(() => {
                        loadAborted?.();
                        setIsProcessing(false);
                    });
            } else {
                try {
                    const objUrl = URL.createObjectURL(selectedFile);

                    loadFinished?.(objUrl);
                } catch (error) {
                    loadAborted?.();
                } finally {
                    setIsProcessing(false);
                }
            }

            e.target.value = '';
        }
    };

    const handleClick = () => {
        if (!chatApi.bothSidesConnected && !chatApi.offlineRoom) return;
        loadOpen?.();
        inputRef.current?.click();
    };

    return (
        <div className={`file-loader ${className}`}>
            <input
                ref={inputRef}
                type='file'
                style={{display: 'none'}}
                accept={`${fileType}/*`}
                onChange={handleFileChange}
                {...(captureMode === 'camera' ? {capture: 'user'} : {})}
            />
            <RoundedButton
                color={isPhotoChat ? 'white' : 'blue'}
                size={isPhotoChat ? 'larger' : 'normal'}
                icon={icon}
                onClick={handleClick}
                disabled={!(chatApi.bothSidesConnected || chatApi.offlineRoom)}
            />
        </div>
    );
};

export default FileLoader;
