import React, {useState, useRef, useEffect} from 'react';
import './ts-zoomable-image.style.scss';

interface ZoomableImageProps {
    isNew: boolean;
    src: string;
    type: string;
    altText?: string;
    isZoomed: boolean;
    imageLoadStarted: (isNew: boolean, type: string, src: string) => void;
    imageLoaded: (isNew: boolean, type: string, src: string) => void;
    imageLoadFailed: (isNew: boolean, type: string, src: string, err: any) => void;
}

const ZOOM_MAX_MARGIN = 10;

export const ZoomableImage: React.FC<ZoomableImageProps> = ({
    isNew,
    src,
    type,
    altText,
    isZoomed,
    imageLoadStarted,
    imageLoaded,
    imageLoadFailed
}) => {
    const [zoomed, setZoomed] = useState(isZoomed);
    const [imageSize, setImageSize] = useState({width: 'auto', height: 'auto'});
    const [margin, setMargin] = useState({top: '0px', left: 'auto'});
    const [zoomedSize, setZoomedSize] = useState({width: 'auto', height: 'auto'});
    const [position, setPosition] = useState({x: 0, y: 0});
    const [isDragging, setIsDragging] = useState(false);
    const [startPos, setStartPos] = useState({x: 0, y: 0});

    const containerRef = useRef<HTMLDivElement>(null);
    const imgRef = useRef<HTMLImageElement>(null);

    let isMounted = true;

    useEffect(() => {
        if (!imgRef.current || !containerRef.current) return;

        imageLoadStarted(isNew, type, src);

        const img = imgRef.current;
        const container = containerRef.current;

        const updateSize = () => {
            if (!isMounted) return;

            const cWidth = container.clientWidth;
            const cHeight = container.clientHeight;
            const iWidth = img.naturalWidth;
            const iHeight = img.naturalHeight;

            let minimizedWidth, minimizedHeight;
            if (cWidth / cHeight > iWidth / iHeight) {
                minimizedHeight = cHeight;
                minimizedWidth = (cHeight / iHeight) * iWidth;
            } else {
                minimizedWidth = cWidth;
                minimizedHeight = (cWidth / iWidth) * iHeight;
            }

            if (!isMounted) return;

            setImageSize({width: `${minimizedWidth}px`, height: `${minimizedHeight}px`});
            setMargin({top: `${(cHeight - minimizedHeight) / 2}px`, left: 'auto'});

            // Set zoomed size (at least 2x original)
            const zoomedWidth = Math.max(2 * cWidth, iWidth);
            const zoomedHeight = (minimizedHeight / minimizedWidth) * zoomedWidth;

            setZoomedSize({width: `${zoomedWidth}px`, height: `${zoomedHeight}px`});

            const defaultZoomedMarginTop = (cHeight - zoomedHeight) / 2;
            const defaultZoomedMarginLeft = (cWidth - zoomedWidth) / 2;

            setPosition({y: defaultZoomedMarginTop, x: defaultZoomedMarginLeft});
        };

        img.onload = () => {
            if (!isMounted) return;

            imageLoaded(isNew, type, src);
            updateSize();
        };

        img.onerror = (err) => {
            if (!isMounted) return;

            imageLoadFailed(isNew, type, src, err);
        };

        return () => {
            isMounted = false;
        };
    }, [isNew, src, type]);

    useEffect(() => {
        setZoomed(isZoomed);
    }, [isZoomed]);

    const toggleZoom = () => {
        if (zoomed) {
            setPosition({x: 0, y: 0});
        }

        setZoomed((prevZoom) => !prevZoom);
    };

    const handleMouseDown = (e: React.MouseEvent) => {
        if (!zoomed) return;

        setIsDragging(true);
        setStartPos({x: e.clientX, y: e.clientY});
    };

    const handleMouseMove = (e: React.MouseEvent) => {
        if (!zoomed || !isDragging) return;

        const dx = e.clientX - startPos.x;
        const dy = e.clientY - startPos.y;

        setPosition((prev) => ({
            x: Math.max(-ZOOM_MAX_MARGIN, Math.min(prev.x + dx, ZOOM_MAX_MARGIN)),
            y: Math.max(-ZOOM_MAX_MARGIN, Math.min(prev.y + dy, ZOOM_MAX_MARGIN))
        }));

        setStartPos({x: e.clientX, y: e.clientY});
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    return (
        <div ref={containerRef} className='ts-zoomable-image-component'>
            <img
                ref={imgRef}
                draggable={false}
                alt={altText || ' '}
                src={src}
                onDoubleClick={toggleZoom}
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
                onMouseLeave={handleMouseUp}
                style={{
                    marginTop: zoomed ? `${position.y}px` : margin.top,
                    marginLeft: zoomed ? `${position.x}px` : margin.left,
                    width: zoomed ? zoomedSize.width : imageSize.width,
                    height: zoomed ? zoomedSize.height : imageSize.height
                }}
                className={zoomed ? 'zoom' : ''}
            />
        </div>
    );
};

export default ZoomableImage;
