import React from 'react';
import {requireImage} from '../../utils/utils';
import {supportedThemes} from '@techsee/techsee-common/lib/constants/account.constants';
import {Message} from '../ts-fullscreen-gallery/FullscreenGallery.controller';
import './ts-chat.style.scss';
import {useAutoScroll} from '../../hooks/useAutoScroll';

export interface ChatProps {
    theme: string;
    logoWatermarkImage?: string;
    messages: Message[];
    onImageClicked: (message: Message) => void;
    onRetryImageUploadClicked: (index: number) => void;
    showMediaIndexes?: boolean;
}

export const Chat: React.FC<ChatProps> = ({
    theme,
    logoWatermarkImage,
    messages,
    onImageClicked,
    showMediaIndexes,
    onRetryImageUploadClicked
}) => {
    const chatContainerRef = useAutoScroll(messages);

    const getChatMessageCssClasses = (message: Message) => {
        const isAgentMessage = message.type && message.type.match(/-DB$/);
        return isAgentMessage ? 'message-left' : 'message-right';
    };

    const retryImageUpload = (index: number, event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
        onRetryImageUploadClicked(index);
    };

    const getMessageBackground = (message: Message) => {
        if (!message.isVideo) {
            return `url('${message.data}')`;
        }
        return `url('../../../../img/video-thumbnail.png'), url('${message.data}')`;
    };

    const isMediaMessage = (message: Message) => {
        return ['IMG-DB', 'IMG-CL', 'VID-DB', 'VID-CL'].includes(message.type as string);
    };

    return (
        <div className='ts-chat'>
            {!theme && (
                <div className={'logo-techsee'}>
                    <img src={logoWatermarkImage || requireImage('techsee-watermark.png')} alt={'TechSee'} />
                </div>
            )}

            {theme && theme !== supportedThemes.in && (
                <div className={'logo-techsee'}>
                    <img
                        className={`${theme !== supportedThemes.vgr ? 'branding' : ''}`}
                        src={logoWatermarkImage || requireImage(`${theme}/watermark-photo.png`)}
                        alt='branding'
                    />
                </div>
            )}

            <div className={'chat-scroll-container'} ref={chatContainerRef}>
                <div className='chat-messages-wrapper'>
                    <ul className={'chat-messages-list'}>
                        {messages?.map((message, index) => (
                            <li key={index} className={`chat-list-item ${getChatMessageCssClasses(message)}`}>
                                {isMediaMessage(message) ? (
                                    <div
                                        className={`chat-message ${message.isVideo ? 'chat-video-message' : ''}`}
                                        onClick={() => onImageClicked(message)}>
                                        <div
                                            className={'image-chat-message'}
                                            style={{backgroundImage: getMessageBackground(message)}}>
                                            {showMediaIndexes && message.index && (
                                                <div className={'image-chat-index'}>{message.index}</div>
                                            )}
                                            {message.failed && (
                                                <div className={'retry-upload-container'}>
                                                    <div
                                                        className={'retry-upload-button'}
                                                        onClick={(event) => retryImageUpload(index, event)}>
                                                        <img src={requireImage('upload-failed.png')} />
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                ) : (
                                    <div className={'chat-message'}>
                                        {message.unread && <div className={'red-badge'}></div>}
                                        <div
                                            className={'message-content'}
                                            dangerouslySetInnerHTML={{__html: message.data}}></div>
                                    </div>
                                )}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};
