import React from 'react';
import {observer} from 'mobx-react';
import {getRootStore} from '../../app.bootstrap';
import {GenericModal} from '@techsee/techsee-ui-common/lib/modals/generic';
import Translate from '../Translate';

import './uploadConfirmation.scss';
import {TechseeBaseComponent} from '@techsee/techsee-ui-common/lib/_shared/techsee-base-component';

interface UploadConfirmationProps {
    url?: string;
}

@observer
export class UploadConfirmation extends TechseeBaseComponent<UploadConfirmationProps> {
    renderInternal() {
        const controller = getRootStore().uploadConfirmationController;

        if (!controller.isModalOpen || !controller.url) {
            return <React.Fragment></React.Fragment>;
        }

        return (
            <GenericModal
                isCustomPosition={true}
                className={`${this.renderCssClasses()} upload-confirmation-modal`}
                header={() => <React.Fragment></React.Fragment>}
                content={() => (
                    <div className='confirmation-modal-content'>
                        <div className='confirmation-modal-title'>
                            <Translate text='UPLOAD_CONFIRMATION.VIEW.TITLE'></Translate>
                        </div>

                        <div className='confirmation-modal-sub-title'>
                            <Translate text='UPLOAD_CONFIRMATION.VIEW.SUB_TITLE'></Translate>
                        </div>

                        <div className='confirmation-modal-image'>
                            <img src={controller.url} />
                        </div>

                        <div className='row'>
                            <button type='button' className='btn-default cancel' onClick={controller.close}>
                                <Translate text='UPLOAD_CONFIRMATION.VIEW.CANCEL'></Translate>
                            </button>
                            <button type='button' className='btn-default' onClick={controller.ok}>
                                <Translate text='UPLOAD_CONFIRMATION.VIEW.OK'></Translate>
                            </button>
                        </div>
                    </div>
                )}
                toolbar={() => <React.Fragment></React.Fragment>}
                isCloseVisible={false}
                onHideHandler={() => {}}
                show={true}></GenericModal>
        );
    }
}
