import React from 'react';
import Translate from '../Translate';

import './ts-state-title.style.scss';

export interface StateTitleProps {
    title: string;
}

export const StateTitle: React.FC<StateTitleProps> = ({title}) => {
    return (
        <div className='ts-state-title'>
            <label>
                <Translate text={title} />
            </label>
        </div>
    );
};
