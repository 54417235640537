import React from 'react';
import {RoundedButton} from '../../rounded-button/RoundedButton';

import './ts-cobrowsing-title.style.scss';
import Translate from '../../Translate';

export interface CoBrowsingTitleProps {
    title: string;
    isPaused: boolean;
    isInControl: boolean;
    onEndMeetingClick: () => void;
    onPauseClick: () => void;
    onResumeClick: () => void;
}

export const CoBrowsingTitle: React.FC<CoBrowsingTitleProps> = ({
    title,
    isPaused,
    onEndMeetingClick,
    onPauseClick,
    isInControl,
    onResumeClick
}) => {
    return (
        <div className={`ts-cobrowsing-title ${isPaused ? 'pause-mode' : ''}`}>
            <RoundedButton
                color={!isPaused ? 'blue' : 'light-blue'}
                size={'small'}
                icon={'cb-exit'}
                customCss={'position position-exit'}
                onClick={onEndMeetingClick}
            />
            <RoundedButton
                color={!isPaused ? 'blue' : 'light-blue'}
                size={'small'}
                icon={!isPaused ? 'cb-pause' : 'cb-resume'}
                customCss={`${!isPaused && isInControl ? 'disabled' : ''} position position-pause`}
                disabled={!isInControl}
                onClick={!isPaused ? onPauseClick : onResumeClick}
            />

            <label>
                <Translate text={title} />
            </label>
        </div>
    );
};
