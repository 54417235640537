'use strict';

import get from 'lodash/get';
import assign from 'lodash/assign';
import forEach from 'lodash/forEach';
import sortBy from 'lodash/sortBy';
import filter from 'lodash/filter';

import {CHAT_CONTROL_BLUR_DEBOUNCE_TIMEOUT} from './ts-chat-helper.settings';
import {
    MEDIA_COMPLETION_POPUP_TIMEOUT,
    ROOM_COUNTER_TYPES,
    STATUS_MESSAGES,
    VIEW_PAGES
} from '../../../states/meeting/meeting.settings.js';
import {VIDEO_SIZES, getVideoStyle} from '../../../directives/ts-video-publisher/ts-video-publisher.directive';
import * as socketEvents from '@techsee/techsee-common/lib/socket/client';

// @ts-ignore
import {senderTypes} from '@techsee/techsee-common/lib/constants/resource.constants';
import {UserType, MeetingMode} from '@techsee/techsee-common/lib/constants/room.constants';
// @ts-ignore
import {sanitizeChatMessage} from '@techsee/techsee-common/lib/utils';
import {TsEnvironmentDetect} from '@techsee/techsee-common/lib/helpers/ts-environment-detect';
import {PlatformType} from '@techsee/techsee-common/lib/constants/utils.constant';
import {getRootStore} from '../../app.bootstrap';
import {contextEnum} from '../../component/ts-fullscreen-gallery/FullscreenGallery.controller';

const PARTIAL_SET_STATE = {
    PARTIAL: 'PARTIAL',
    COMPLETE: 'COMPLETE'
};

interface MessageHistory {
    messages: any[];
    imageCount: number;
    videoCount: number;
    pendingMessages: any[];
    pendingMessagesCount: number;
}

interface MediaOptions {
    photoSrc?: any;
    videoSrc?: any;
    origin?: string;
    withConfirm?: boolean;
    withLoader?: boolean;
    retryIndex?: number;
    isPending?: boolean;
    imageSendingCompletion?: () => void;
}

interface ParsedMessage {
    isNew: boolean;
    type: string;
    data: any;
    video: any;
    isVideo: boolean;
    storageIndex: number;
    unread: boolean;
    failed: boolean;
    pending: boolean;
    mediaName?: string;
    appStoreLink?: boolean;
    index?: number;
}

export interface ITsChatHelper {
    getVideoStyle: any;
    videoSettings: {enabled: boolean; videoSize?: string};
    showImageUploadSuccess: boolean;
    showImageUploadFailure: boolean;
    showVideoUploadSuccess: boolean;
    showVideoUploadFailure: boolean;
    PARTIAL_SET_STATE: typeof PARTIAL_SET_STATE;
    VIDEO_SIZES: typeof VIDEO_SIZES;
    messageHistory: MessageHistory;
    tsDashboardEnvironmentDetect?: TsEnvironmentDetect;
    chatControlFocused?: boolean;
    isSendingMedia: boolean;
    selectedImage(message: any): void;
    displayFullscreenGallery(context: any, data: any): void;
    expandButtonClick(disabled: boolean): void;
    setPage(page: string): void;
    onChatControlFocused(isFocused: boolean): void;
    showChatControl(): boolean;
    videoClicked(): void;
    setMessageHistory(messageHistory: MessageHistory): void;
    messages: any[];
    pendingMessages: any[];
    setIsSendingMedia: (isSending: boolean) => void;
    pendingMessageText(textMessage: string): void;
    displayStateTitle(): boolean;
    sendMedia(options: MediaOptions): Promise<any>;
    setMode(): void;
    closePopups(): void;
    isThumbnailMode(): boolean;
    _setCurrentPage(page: string): void;
    isInSpeedtestPage: boolean;
}

export class TsChatHelper implements ITsChatHelper {
    private chatApi: any;
    tsAppstoreUrlUtils: any;
    stateHelper: any;
    fullscreenGalleryController: any;
    currentPage: string;
    getVideoStyle: any;
    videoSettings: {enabled: boolean; videoSize?: string};
    showImageUploadSuccess: boolean;
    showImageUploadFailure: boolean;
    showVideoUploadSuccess: boolean;
    showVideoUploadFailure: boolean;
    EventService: any;
    videoFixer: any;
    uploadConfirmationModal: any;
    partialSetsOfImageWithSteps: any[];
    failedMessageOffset: number;
    imageUploadRetryIndex: number;
    PARTIAL_SET_STATE: typeof PARTIAL_SET_STATE;
    VIDEO_SIZES: typeof VIDEO_SIZES;
    messageHistory: MessageHistory;
    dashboardUA?: string;
    tsDashboardEnvironmentDetect?: TsEnvironmentDetect;
    chatControlFocused?: boolean;
    previousPage?: string;
    isSendingMedia: boolean;

    constructor() {
        this.chatApi = getRootStore().chatApi;
        this.tsAppstoreUrlUtils = getRootStore().appstoreUrlUtils;
        this.stateHelper = getRootStore().stateHelper;
        this.fullscreenGalleryController = getRootStore().fullscreenGalleryController;
        this.currentPage = VIEW_PAGES.MAIN;
        this.getVideoStyle = getVideoStyle;
        this.videoSettings = {enabled: false};
        this.showImageUploadSuccess = false;
        this.showImageUploadFailure = false;
        this.showVideoUploadSuccess = false;
        this.showVideoUploadFailure = false;
        this.EventService = getRootStore().eventService;
        this.videoFixer = getRootStore().videoFixer;
        this.uploadConfirmationModal = getRootStore().uploadConfirmationController;
        this.partialSetsOfImageWithSteps = [];
        this.failedMessageOffset = 0;
        this.imageUploadRetryIndex = -1;
        this.PARTIAL_SET_STATE = PARTIAL_SET_STATE;
        this.VIDEO_SIZES = VIDEO_SIZES;
        this.messageHistory = {
            messages: [],
            imageCount: 0,
            videoCount: 0,
            pendingMessages: [],
            pendingMessagesCount: 0
        };

        this.selectedImage = this.selectedImage.bind(this);
        this.displayFullscreenGallery = this.displayFullscreenGallery.bind(this);
        this.expandButtonClick = this.expandButtonClick.bind(this);
        this._setCurrentPage = this._setCurrentPage.bind(this);
        this._appendHistoryMessage = this._appendHistoryMessage.bind(this);
        this._setDashboardUA = this._setDashboardUA.bind(this);
        this.chatApi.on(socketEvents.CLIENT_IN.MESSAGE, this._appendHistoryMessage);
        this.chatApi.on(socketEvents.CLIENT_IN.SYNC, this._setDashboardUA);
        this.isSendingMedia = false;
    }

    setMessageHistory(messageHistory: MessageHistory) {
        this.messageHistory = messageHistory;
    }

    async _setDashboardUA() {
        const dashboardUA = get(this.chatApi, 'dashboard.browser.userAgent');

        if (dashboardUA !== this.dashboardUA) {
            this.dashboardUA = dashboardUA;
            this.tsDashboardEnvironmentDetect = new TsEnvironmentDetect(dashboardUA, undefined, true);
        }
    }

    _setCurrentPage(page: string) {
        if (page === this.currentPage) {
            return;
        }

        if (this.isInImagePage) {
            this._closeFullscreenGallery();
        }

        if (this.isInChatPage) {
            this.messageHistory.messages = this.setUnreadPropertyMessage(this.messageHistory);
        }

        // IMAGE page does not show the chat control, so it should be de-focused automatically
        if (page === VIEW_PAGES.IMAGE) {
            this.chatControlFocused = false;
        }

        this.previousPage = this.currentPage;
        this.currentPage = page || VIEW_PAGES.MAIN;

        if (this.videoSettings) {
            this.videoSettings.videoSize = this._decideOnVideoSize();
        }
    }

    // TODO: message type
    selectedImage(message: any) {
        this.displayFullscreenGallery(contextEnum.MESSAGE_CLICKED, message.index);
    }

    // TODO: context, data type
    displayFullscreenGallery(context: any, data: any) {
        this.chatApi.sendLog(STATUS_MESSAGES.CUSTOMER_IN_PHOTOGALLERY_PAGE);
        this.EventService.sendEventLog(
            'none',
            this.chatApi.roomId || 'none',
            STATUS_MESSAGES.CUSTOMER_IN_PHOTOGALLERY_PAGE
        );
        this.fullscreenGalleryController.openGallery({context, data, messages: this.messageHistory.messages});
        this._setCurrentPage(VIEW_PAGES.IMAGE);
    }

    _closeFullscreenGallery() {
        this.chatApi.sendLog(STATUS_MESSAGES.CUSTOMER_CLOSED_PHOTOGALLERY_PAGE);
        this.EventService.sendEventLog(
            'none',
            this.chatApi.roomId || 'none',
            STATUS_MESSAGES.CUSTOMER_CLOSED_PHOTOGALLERY_PAGE
        );
        this.fullscreenGalleryController.closeGallery();
    }

    expandButtonClick(disabled: boolean) {
        if (this.chatControlFocused || disabled) {
            return;
        }

        if (this.isInMainPage) {
            return this._setCurrentPage(VIEW_PAGES.CHAT);
        }

        if (this.isInChatPage) {
            return this._setCurrentPage(VIEW_PAGES.MAIN);
        }

        // From IMAGE state we should return to the previous state
        return this._setCurrentPage(this.previousPage || VIEW_PAGES.MAIN);
    }

    get isInSpeedtestPage() {
        return this.currentPage === VIEW_PAGES.SPEEDTEST;
    }

    setPage(page: string) {
        return this._setCurrentPage(page);
    }

    get isInMainPage() {
        return this.currentPage === VIEW_PAGES.MAIN;
    }

    get isMobileDashboard() {
        return this.tsDashboardEnvironmentDetect && this.tsDashboardEnvironmentDetect.isMobile();
    }

    get isInChatPage() {
        return this.currentPage === VIEW_PAGES.CHAT;
    }

    get isInImagePage() {
        return this.currentPage === VIEW_PAGES.IMAGE;
    }

    onChatControlFocused(isFocused: boolean) {
        setTimeout(() => {
            this.chatControlFocused = isFocused;
        }, CHAT_CONTROL_BLUR_DEBOUNCE_TIMEOUT);
    }

    showChatControl() {
        const allowClientChat = get(this.chatApi, 'accountSettings.allowClientChat');

        return (
            allowClientChat &&
            this.currentPage !== VIEW_PAGES.IMAGE &&
            !this.isInSpeedtestPage &&
            !this.isMobileDashboard
        );
    }

    _decideOnVideoSize() {
        return this.isInMainPage ? VIDEO_SIZES.NORMAL : VIDEO_SIZES.THUMBNAIL;
    }

    videoClicked() {
        if (!this.isInMainPage) {
            this._setCurrentPage(VIEW_PAGES.MAIN);
        }
    }

    setUnreadPropertyMessage(messageHistory: MessageHistory) {
        return forEach(messageHistory.messages, (msg) => {
            msg.unread = false;
        });
    }

    // TODO: stepMsg, parsedStepMsg type
    _checkWaitForImageSet(stepMsg: any, parsedStepMsg: any) {
        const stepCount = get(stepMsg, 'data.meta.stepCount');
        const setId = get(stepMsg, 'data.meta.setId');
        const metaStep = get(stepMsg, 'data.meta');
        const newStep = assign(parsedStepMsg, metaStep);

        if (!setId || !stepCount || stepCount < 2) {
            return [newStep];
        }

        const partialSet = this.partialSetsOfImageWithSteps[setId];

        if (!partialSet) {
            this.partialSetsOfImageWithSteps[setId] = [newStep];

            return;
        }

        this.partialSetsOfImageWithSteps[setId].push(newStep);

        // If not all steps arrived yet
        if (partialSet.length < stepCount) {
            return;
        }

        const orderedSet = sortBy(partialSet, (step) => step.stepIndex);

        delete this.partialSetsOfImageWithSteps[setId];

        return orderedSet;
    }

    // TODO: msg, sentByDashboard type
    _getMessageIndex(msg: any, sentByDashboard?: boolean) {
        if (msg.failed) {
            this.failedMessageOffset += 1;

            return this.messageHistory.messages.length + 1;
        }

        if (this.imageUploadRetryIndex !== -1 && sentByDashboard) {
            this.failedMessageOffset -= 1;

            return this.imageUploadRetryIndex + 1;
        }

        return msg.storageIndex + this.failedMessageOffset;
    }

    /*
     * Takes a message from either the server or the client itself and
     * pushes it in the message list, after processing it to
     * be 'friendlier' to the view.
     *
     * @param msg - Incoming message
     */

    //TODO: message type
    _appendHistoryMessage(message: any, skipNotify?: boolean) {
        let isPartialSet = null;
        let firstMessageInSet = null;
        const isImage = message.data.type === 'image';
        const isVideo = message.data.type === 'video';
        const isText = message.data.type === 'text';

        const sentByDashboard = message.sender === UserType.dashboard;
        const newMessageFromDashboard = message.isNew && sentByDashboard;

        if (newMessageFromDashboard) {
            this.EventService.sendEventLog(
                'none',
                this.chatApi.roomId || 'none',
                STATUS_MESSAGES.RECEIVED_MESSAGE,
                sanitizeChatMessage(assign({side: PlatformType.mobile_web}, message.data))
            );
        }

        if (newMessageFromDashboard && isImage) {
            this.chatApi.incrementCounter(ROOM_COUNTER_TYPES.CLIENT_RECEIVED_ANNOTATED_IMAGES, message);
        }

        const messageIndex = this._getMessageIndex(message);

        const parsedMsg: ParsedMessage = {
            isNew: message.isNew,
            type: isImage ? 'IMG-' : isVideo ? 'VID-' : 'MSG-',
            data: isVideo ? message.data.extra : message.data.message,
            video: message.data.message,
            isVideo: isVideo,
            storageIndex: messageIndex,
            unread: newMessageFromDashboard,
            failed: message.failed,
            pending: message.pending
        };

        if ((parsedMsg.failed || parsedMsg.pending) && message.data && message.data.mediaName) {
            parsedMsg.mediaName = message.data.mediaName;
        }

        if (sentByDashboard && isText && this.tsAppstoreUrlUtils.isAppstoreLink(parsedMsg.data)) {
            parsedMsg.appStoreLink = true;
        }

        parsedMsg.type += sentByDashboard ? 'DB' : 'CL';

        const _handleImage = (parsedMsg: ParsedMessage) => {
            if (isImage && !message.failed && !message.pending) {
                parsedMsg.index = ++this.messageHistory.imageCount;
            } else if (isVideo) {
                parsedMsg.index = ++this.messageHistory.videoCount;
            }

            if (!isImage || this.imageUploadRetryIndex === -1 || sentByDashboard) {
                this.messageHistory.messages.push(parsedMsg);
            } else {
                const failedMessageObjectUrl = this.messageHistory.messages[this.imageUploadRetryIndex].data;

                window.URL.revokeObjectURL(failedMessageObjectUrl);

                this.messageHistory.messages[this.imageUploadRetryIndex] = parsedMsg;
                this.imageUploadRetryIndex = -1;
            }
        };

        const imageFromDashboardWithSteps = sentByDashboard && isImage && get(message, 'data.meta.stepCount');

        if (!imageFromDashboardWithSteps) {
            _handleImage(parsedMsg);
        } else {
            const allImageSteps = this._checkWaitForImageSet(message, parsedMsg);

            isPartialSet = allImageSteps ? PARTIAL_SET_STATE.COMPLETE : PARTIAL_SET_STATE.PARTIAL;

            if (allImageSteps) {
                forEach(allImageSteps, (stepMsg) => _handleImage(stepMsg));

                if (isPartialSet === PARTIAL_SET_STATE.COMPLETE) {
                    firstMessageInSet = allImageSteps[0];
                }
            }
        }

        this.messageHistory.messages = this.messages;
        this.messageHistory.pendingMessages = this.pendingMessages;
        this.messageHistory.pendingMessagesCount = this.messageHistory.pendingMessages.length;

        // Notify the server that the client got the message
        if (newMessageFromDashboard) {
            if (isImage) {
                this.chatApi.sendLog(STATUS_MESSAGES.GOT_IMAGE, message.timestamp);
            } else if (isVideo) {
                this.chatApi.sendLog(STATUS_MESSAGES.GOT_VIDEO, message.timestamp);
            } else {
                this.chatApi.sendLog(STATUS_MESSAGES.GOT_TEXT, message.timestamp);
            }
        } else if (message.isNew && isText) {
            this.chatApi.sendLog(STATUS_MESSAGES.SENT_TEXT, message.timestamp);
        }

        if (skipNotify !== true) {
            this.chatApi.notifyNewHistoryMessage({message: message, isPartialSet, firstMessageInSet});
        }
    }

    get messages() {
        return filter(this.messageHistory.messages, (message) => message.pending !== false);
    }

    get pendingMessages() {
        return filter(this.messageHistory.messages, (message) => message.pending);
    }

    setIsSendingMedia(isSending: boolean) {
        this.isSendingMedia = isSending;
    }

    pendingMessageText(textMessage: string) {
        this.messageHistory.messages.push({
            data: textMessage,
            isNew: true,
            isVideo: false,
            pending: true,
            type: 'MSG-CL',
            unread: false,
            video: textMessage
        });

        this.messageHistory.pendingMessages = filter(this.messageHistory.messages, (message) => message.pending);
        this.messageHistory.pendingMessagesCount = this.messageHistory.pendingMessages.length;
    }

    displayStateTitle() {
        return (
            (this.chatApi.areBothSidesConnected && !this.isInSpeedtestPage) ||
            (this.chatApi.offlineRoom &&
                this.chatApi.client.mode !== MeetingMode.video &&
                this.chatApi.client.mode !== MeetingMode.screen)
        );
    }

    _uploadConfirmationRequired(confirmationRequired: boolean) {
        return (
            confirmationRequired &&
            get(this.chatApi, 'accountSettings.enableUploadConfirmationStep') &&
            this.chatApi.connected
        );
    }

    // TODO: videoSrc type
    _validateVideoSize(videoSrc: any, storedVideoMaxSizeInMb: number) {
        return videoSrc.size <= storedVideoMaxSizeInMb * 1000000;
    }

    // TODO: videoSrc type
    _buildOverMaxSizeErrorString(
        videoSrc: {size: number; duration: number},
        storedVideoMaxSizeInMb: number
    ): Promise<string> {
        return Promise.resolve().then(() => {
            const videoSizeInMb = (videoSrc.size / 1_000_000).toFixed(2);
            const videoMaxDuration = Math.ceil(
                (storedVideoMaxSizeInMb / parseFloat(videoSizeInMb)) * videoSrc.duration
            );

            const formatString = (str: string, args: Record<string, any>) =>
                str.replace(/\{\s*([^}\s]+)\s*\}/g, (m, iArg) => args[iArg]);

            return formatString(
                getRootStore().localizationService.translate('FAILED_UPLOAD.VIEW.VIDEO_OVER_MAX_SIZE'),
                {videoMaxDuration}
            );
        });
    }

    /*
     *   options: { photoSrc, videoSrc, origin, withConfirm, withLoader, retryIndex }
     *
     *   When confirmation required and user click cancel on confirmation, this function rejects with "null" param.
     *   When uploading to AWS failed or uploaded media url not exists, the function rejects with photoSrc/videoSrc and error.
     * */
    sendMedia(options: MediaOptions) {
        const {photoSrc, videoSrc, origin, withConfirm, withLoader, retryIndex, isPending, imageSendingCompletion} =
            options;
        let retryMediaFileName: string | null = null;

        if (typeof retryIndex === 'number') {
            this.imageUploadRetryIndex = retryIndex;
            retryMediaFileName = this.messageHistory.messages[retryIndex].mediaName;

            this.EventService.sendEventLog(
                'none',
                this.chatApi.roomId || 'none',
                STATUS_MESSAGES.CUSTOMER_RESENDING_IMAGE,
                {mediaFileName: retryMediaFileName}
            );
        }

        const isVideo = !!videoSrc;
        const mediaSrc = isVideo ? videoSrc : photoSrc;

        if (isVideo) {
            const storedVideoMaxSizeInMb = get(this.chatApi, 'accountSettings.storedVideoMaxSizeInMb') || 5;

            if (!this._validateVideoSize(mediaSrc, storedVideoMaxSizeInMb)) {
                return this._buildOverMaxSizeErrorString(videoSrc, storedVideoMaxSizeInMb).then((err) => {
                    this._videoUploadFailHandler(err, retryMediaFileName, withLoader);

                    return Promise.reject(err);
                });
            }
        }

        let thumbnailImage: string | null = null;

        const createThumbnailPromise = isVideo
            ? this.videoFixer.createThumbnail(mediaSrc.file)
            : Promise.resolve(mediaSrc);

        const uploadConfirmation = (imageToConfirm: string) => {
            if (!this._uploadConfirmationRequired(!!withConfirm)) {
                return Promise.resolve(true);
            }

            return this._getUploadConfirmation(imageToConfirm).catch((err: Error) => {
                this._videoUploadFailHandler(err, retryMediaFileName, withLoader);

                return false;
            });
        };

        return new Promise((resolve, reject) => {
            return createThumbnailPromise
                .then((imageToConfirm: string) => {
                    if (isVideo) {
                        thumbnailImage = imageToConfirm;
                    }

                    return uploadConfirmation(imageToConfirm);
                })
                .then((gotConfirmation: boolean) => {
                    if (!gotConfirmation) {
                        reject(null);

                        return;
                    }

                    const url = isVideo ? mediaSrc.url : mediaSrc;

                    this.chatApi
                        .generateMediaFileName(url, isVideo, retryMediaFileName)
                        .then(({blob, mediaFileName}: {blob: string; mediaFileName: string}) => {
                            retryMediaFileName = mediaFileName;

                            this.chatApi.sendLog(
                                isVideo
                                    ? STATUS_MESSAGES.CUSTOMER_UPLOADING_A_VIDEO
                                    : STATUS_MESSAGES.CUSTOMER_UPLOADING_AN_IMAGE
                            );
                            this.EventService.sendEventLog(
                                'none',
                                this.chatApi.roomId || 'none',
                                isVideo
                                    ? STATUS_MESSAGES.CUSTOMER_UPLOADING_A_VIDEO
                                    : STATUS_MESSAGES.CUSTOMER_UPLOADING_AN_IMAGE,
                                {origin: origin, mediaFileName: mediaFileName}
                            );

                            if (withLoader) {
                                this.isSendingMedia = true;
                            }

                            return {blob, mediaFileName};
                        })
                        .then(({blob, mediaFileName}: {blob: string; mediaFileName: string}) =>
                            this.chatApi.uploadRoomMedia(blob, isVideo, mediaFileName)
                        )
                        .then((result: {mediaUrl: string; mediaName: string}) => {
                            window.URL.revokeObjectURL(url);

                            if (!result || !result.mediaUrl) {
                                const err = {photoSrc: url, error: 'There was no publicUrl in the response from aws'};

                                if (isVideo) {
                                    this._videoUploadFailHandler(err, retryMediaFileName, withLoader);
                                } else {
                                    this._imgUploadFailHandler(
                                        err,
                                        retryMediaFileName,
                                        imageSendingCompletion,
                                        withLoader
                                    );
                                }

                                reject(err);

                                return;
                            }

                            if (withLoader) {
                                this.isSendingMedia = false;
                            }

                            if (!isVideo) {
                                return this.chatApi
                                    .sendImage(result.mediaUrl, {
                                        sharedBy: senderTypes.CLIENT,
                                        mediaFileName: retryMediaFileName
                                    })
                                    .then(() => {
                                        this.chatApi.incrementCounter(ROOM_COUNTER_TYPES.CLIENT_SENT_IMAGES);

                                        this._imageSent(true, !!isPending, imageSendingCompletion);

                                        if (typeof retryIndex === 'number' && this.imageUploadRetryIndex !== -1) {
                                            delete this.messageHistory.messages[retryIndex].failed;
                                        }

                                        resolve(result);
                                    })
                                    .catch((err: Error) => {
                                        this._imgUploadFailHandler(
                                            'skip',
                                            retryMediaFileName,
                                            imageSendingCompletion,
                                            withLoader
                                        );

                                        reject(err);
                                    });
                            }

                            const thumbnailFileName = this.videoFixer
                                .getThumbnailUrl(result.mediaUrl.split('?')[0])
                                .replace(/\/$/, '')
                                .split('/')
                                .pop();

                            this.chatApi
                                .uploadRoomMedia(thumbnailImage, false, thumbnailFileName)
                                .then((thumbnailResult: {mediaUrl: string; mediaName: string}) => {
                                    this.chatApi.sendVideo(result.mediaUrl, thumbnailResult.mediaUrl, {
                                        sharedBy: senderTypes.CLIENT,
                                        mediaFileName: retryMediaFileName
                                    });
                                    this.chatApi.incrementCounter(ROOM_COUNTER_TYPES.CLIENT_SENT_VIDEOS);

                                    this._videoSent(true, !!isPending);

                                    resolve({video: result, thumbnail: thumbnailResult});
                                })
                                .catch((err: Error) => {
                                    this._videoUploadFailHandler(
                                        'Failed to upload thumbnail ' + err,
                                        retryMediaFileName,
                                        withLoader
                                    );

                                    reject(err);
                                });
                        })
                        .catch((err: Error) => {
                            if (isVideo) {
                                this._videoUploadFailHandler(err, retryMediaFileName, withLoader);
                            } else {
                                this._imgUploadFailHandler(err, retryMediaFileName, imageSendingCompletion, withLoader);
                            }

                            reject(err);
                        });
                });
        }).catch((err: Error) => {
            if (err) {
                if (this.imageUploadRetryIndex === -1) {
                    const historyMessage = {
                        data: {
                            type: isVideo ? 'video' : 'image',
                            message: isVideo ? videoSrc : photoSrc,
                            mediaName: retryMediaFileName
                        },
                        failed: this.chatApi.connected,
                        pending: !this.chatApi.connected,
                        sender: UserType.client,
                        index: this.messageHistory.messages.length + 1
                    };

                    this._appendHistoryMessage(historyMessage, true);
                }

                this.imageUploadRetryIndex = -1;
            }

            return Promise.reject(err);
        });
    }

    setMode() {
        this.chatApi.setStatus(socketEvents.CLIENT_OUT_SET_STATUS.PREPARING, false);
    }

    // TODO
    _imgUploadFailHandler(
        err: any,
        mediaFileName: string | null,
        imageSendingCompletion?: () => void,
        progressLoaderToggle = true
    ) {
        if (err !== 'skip') {
            this.chatApi.sendLog(STATUS_MESSAGES.CUSTOMER_S3_IMAGE_UPLOAD_FAILED);
            this.EventService.sendEventLog(
                'none',
                this.chatApi.roomId || 'none',
                STATUS_MESSAGES.CUSTOMER_S3_IMAGE_UPLOAD_FAILED,
                {
                    error: err,
                    mediaFileName: mediaFileName
                }
            );
        }

        if (progressLoaderToggle) {
            this.isSendingMedia = false;
        }

        this._imageSent(false, !this.chatApi.connected, imageSendingCompletion);
    }

    _videoUploadFailHandler(err: any, mediaFileName: string | null, progressLoaderToggle = true) {
        this.chatApi.sendLog(STATUS_MESSAGES.CUSTOMER_S3_VIDEO_UPLOAD_FAILED);
        this.EventService.sendEventLog(
            'none',
            this.chatApi.roomId || 'none',
            STATUS_MESSAGES.CUSTOMER_S3_VIDEO_UPLOAD_FAILED,
            {
                error: err,
                mediaFileName
            }
        );

        if (progressLoaderToggle) {
            this.isSendingMedia = false;
        }

        this._videoSent(false, !this.chatApi.connected);
    }

    _imageSent(success: boolean, isPending: boolean, imageSendingCompletion?: () => void) {
        // Reset existing popups
        this.showImageUploadSuccess = false;
        this.showImageUploadFailure = false;

        if (success && !isPending) {
            this.showImageUploadSuccess = true;

            return setTimeout(() => {
                this.showImageUploadSuccess = false;
                this.chatApi._safeApply();

                if (imageSendingCompletion) {
                    imageSendingCompletion();
                }
            }, MEDIA_COMPLETION_POPUP_TIMEOUT);
        }

        if (!isPending) {
            this.showImageUploadFailure = true;
        }

        setTimeout(() => {
            this.showImageUploadFailure = false;

            if (imageSendingCompletion) {
                imageSendingCompletion();
            }
        }, MEDIA_COMPLETION_POPUP_TIMEOUT);

        return;
    }

    _videoSent(success: boolean, isPending: boolean) {
        // Reset existing popups
        this.showVideoUploadSuccess = false;
        this.showVideoUploadFailure = false;

        if (success && !isPending) {
            this.showVideoUploadSuccess = true;

            return setTimeout(() => {
                this.showVideoUploadSuccess = false;
            }, MEDIA_COMPLETION_POPUP_TIMEOUT);
        }

        if (!isPending) {
            this.showVideoUploadFailure = true;
        }

        setTimeout(() => {
            this.showVideoUploadFailure = false;
        }, MEDIA_COMPLETION_POPUP_TIMEOUT);

        return;
    }

    _getUploadConfirmation(url: string) {
        this.chatApi.sendLog(STATUS_MESSAGES.UPLOAD_CONFIRMATION_POP_UP, new Date().getTime());
        this.EventService.sendEventLog(
            'none',
            this.chatApi.roomId || 'none',
            STATUS_MESSAGES.UPLOAD_CONFIRMATION_POP_UP
        );

        return this.uploadConfirmationModal.show(url).then((result: boolean) => {
            const resultStatus = result
                ? STATUS_MESSAGES.UPLOAD_CONFIRMATION_OK
                : STATUS_MESSAGES.UPLOAD_CONFIRMATION_CANCEL;

            this.chatApi.sendLog(resultStatus, new Date().getTime());
            this.EventService.sendEventLog('none', this.chatApi.roomId || 'none', resultStatus);

            return result;
        });
    }

    closePopups() {
        this.uploadConfirmationModal.close();

        if (!this.isInMainPage) {
            this._setCurrentPage(VIEW_PAGES.MAIN);
        }
    }

    isThumbnailMode() {
        return this.videoSettings.videoSize === this.VIDEO_SIZES.THUMBNAIL;
    }
}
