import {useEffect, useRef} from 'react';

const FRAME_RATE = 30; // in milliseconds
const SCROLL_DURATION = 400; // in milliseconds

export const useAutoScroll = <T>(items: T[], behavior: 'smooth' | 'instant' = 'smooth') => {
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!containerRef.current) return;

        const container = containerRef.current;
        const startValue = container.scrollTop;
        const endValue = container.scrollHeight;

        if (behavior === 'instant') {
            container.scrollTop = endValue;
            return;
        }

        let stepValue = startValue;
        let distance = endValue - startValue;
        let steps = (SCROLL_DURATION * FRAME_RATE) / 1000;
        const step = distance / steps;

        const scrollInterval = setInterval(() => {
            stepValue += step;
            container.scrollTop = stepValue;
            steps--;

            if (steps <= 0) {
                clearInterval(scrollInterval);
            }
        }, FRAME_RATE);

        return () => clearInterval(scrollInterval);
    }, [items, behavior]);

    return containerRef;
};
