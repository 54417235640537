'use strict';

import get from 'lodash/get';
import {STATUS_MESSAGES, ROOM_MODES, GALLERY_VIEW_PAGES} from '../meeting.settings.js';
import {UserType} from '@techsee/techsee-common/lib/constants/room.constants';
import {MeetingState} from '@techsee/techsee-common/lib/constants/meeting.states.definition';
import {MeetingStateControllerBase} from '../meeting.state.controller.base';
import * as socketEvents from '@techsee/techsee-common/lib/socket/client';
import {getRootStore} from '../../../_react_/app.bootstrap';
import {HTTPS} from '@techsee/techsee-common/lib/constants/utils.constant';
import {contextEnum} from '../../../_react_/component/ts-fullscreen-gallery/FullscreenGallery.controller';

export class CoBrowsingController extends MeetingStateControllerBase {
    constructor($scope, $rootScope, $stateParams, $timeout, db, roomInfo, changeUrlModal) {
        'ngInject';

        super($rootScope, $scope, MeetingState.CoBrowsing, db, getRootStore().eventService);

        this.$scope = $scope;
        this.$rootScope = $rootScope;
        this.$timeout = $timeout;
        this.stateHelper = getRootStore().stateHelper;
        this.db = db;
        this.tsTermsAndConditions = getRootStore().termsAndConditionsController;
        this.chatApi = getRootStore().chatApi;
        this.endParams = $stateParams.csi ? {csi: $stateParams.csi} : {};
        this.roomId = roomInfo.roomId;
        this.ROOM_MODES = ROOM_MODES;
        this.chatHelper = getRootStore().chatHelper;
        this.messageHistory = getRootStore().chatHelper.messageHistory;
        this.fullscreenGalleryController = getRootStore().fullscreenGalleryController;
        this.tsCobrowsing = getRootStore().cobrowsingService;
        this.coBrowsingInitialUrl = roomInfo.coBrowsingInitialUrl;
        this.theme = getRootStore().theme;
        this.brandingService = getRootStore().brandingService;
        this.brandingData = this.brandingService.getBrandingData();
        this.drawMode = false;
        this.endMeetingConfirmationController = getRootStore().endMeetingConfirmationController;
        this.endMeetingConfirmationController.init(true);
        this.changeUrlModal = changeUrlModal;

        this.$scope.$on('$viewContentLoaded', () => {
            // make the code execute on the next cycle
            this.$timeout(() => {
                this.tsEventService.sendEventLog(
                    'none',
                    this.roomId || 'none',
                    STATUS_MESSAGES.CLIENT_PHOTO_VIEW_LOADED
                );
            }, 0);
        });

        this.$scope.$watch(
            () => get(this.chatApi, 'dashboard.coBrowsingSettings.leaderLink'),
            () => {
                const leaderLink = get(this.chatApi, 'dashboard.coBrowsingSettings.leaderLink');

                this.tsCobrowsing.start(null, null, get(this.chatApi, 'accountSettings.coBrowsing.surflyWidgetKey'));
                if (leaderLink) {
                    this.leaderLinkAvailable = true;
                }
            }
        );

        this._onHistoryMessageReceived = this._onHistoryMessageReceived.bind(this);
        this._cancelDraw = this._cancelDraw.bind(this);
        this.relocateUrl = this.relocateUrl.bind(this);

        getRootStore().visibilityChange.enableVisibilityEvents();

        this.notifyConstructionIsReady();
    }

    get handshakeFailureStatusMessage() {
        return STATUS_MESSAGES.CO_BROWSING_FAILED;
    }

    meetingModeHandshake() {
        return this.tsTermsAndConditions.syncTOS(this.chatApi, ROOM_MODES.coBrowsing).then((isApproved) => {
            if (isApproved) {
                this.chatHelper.setMode();
                this.chatApi.setStatus(socketEvents.CLIENT_OUT_SET_STATUS.COBROWSING_HANDSHAKE_SUCCESS, true);
            }
        });
    }

    syncMeetingState() {}

    initModeHandlers() {
        const width = window.innerWidth > 0 ? window.innerWidth : screen.width;
        const height = window.innerHeight > 0 ? window.innerHeight : screen.height;
        const heightOfTechseeBar = 63;

        this.chatApi.setStatus(socketEvents.CLIENT_OUT_SET_STATUS.VIEW_RECTANGLE, {
            height: height
                ? height - (get(this.chatApi, 'accountSettings.allowClientChat') ? 44 : 0) - heightOfTechseeBar
                : '80%',
            width: width ? width : '80%'
        });

        this.db.Rooms.createInstance({_id: this.chatApi.roomId}).changeAgent(ROOM_MODES.coBrowsing);

        this.chatApi.on(socketEvents.CLIENT_IN.NEW_HISTORY_MESSAGE_RECEIVED, this._onHistoryMessageReceived);

        this.$scope.$on('$destroy', () => {
            this.chatHelper.closePopups();
            this.chatApi.off(socketEvents.CLIENT_IN.NEW_HISTORY_MESSAGE_RECEIVED, this._onHistoryMessageReceived);
        });
    }

    //#endregion Meeting Pipeline

    isInChatPage() {
        return this.chatHelper.isInChatPage;
    }

    showCoBrowsingGlassWindow() {
        return (
            !this.tsCobrowsing.isInControl &&
            !this.isInChatPage() &&
            !this.tsTermsAndConditions.isModalOpen &&
            this.leaderLinkAvailable
        );
    }

    showCoBrowsingLoader() {
        return (
            (this.tsCobrowsing.showLoader || this.tsCobrowsing.otherSideLoading) &&
            !this.tsTermsAndConditions.isModalOpen &&
            this.leaderLinkAvailable
        );
    }

    switchControl() {
        this.tsCobrowsing.switchControl();
    }

    paused() {
        this.tsCobrowsing.paused();
    }

    resume() {
        this.tsCobrowsing.resume();
    }

    relocate(url) {
        this.tsCobrowsing.relocate(url);
    }

    _cancelDraw() {
        console.log('_cancelDraw');
        if (this.drawMode) {
            this.onDrawMode(false);
            this.drawMode = false;
        }
    }

    relocateUrl() {
        console.log('relocateUrl');
        this._cancelDraw();

        const modal = this.changeUrlModal.show(HTTPS);

        if (!modal) {
            return;
        }

        return modal.then((newUrl) => newUrl && this._relocateUrl(newUrl)).catch(() => false);
    }

    _relocateUrl(url) {
        this._cancelDraw();
        this.relocate({url: url});
    }

    onDrawMode(enabled) {
        this.tsCobrowsing.draw(enabled);
    }

    _onHistoryMessageReceived(newHistoryMessageData) {
        const {isPartialSet, firstMessageInSet, message} = newHistoryMessageData;

        this.messageHistory = this.chatHelper.messageHistory;

        if (isPartialSet && isPartialSet !== this.chatHelper.PARTIAL_SET_STATE.COMPLETE) {
            return;
        }

        const isMessageFromClient = get(message, 'sender') === UserType.client;

        if (!message.isNew || isMessageFromClient) {
            return;
        }

        if (this.fullscreenGalleryController.isSupportedMsg(message)) {
            if (isPartialSet === this.chatHelper.PARTIAL_SET_STATE.COMPLETE) {
                return this.chatHelper.displayFullscreenGallery(contextEnum.SET_COMPLETED, firstMessageInSet);
            }

            return this.chatHelper.displayFullscreenGallery(contextEnum.NEW_MESSAGE_RECEIVED);
        }

        // New text message from dashboard
        return this.chatHelper._setCurrentPage(GALLERY_VIEW_PAGES.CHAT);
    }
}
